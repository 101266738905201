import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import transportadoraRepositorio from '../../repositorios/transportadora/transportadoraRepositorio';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import KDialogCadastro from '../../components/KDialogCadastro';
import KMaskTextField from '../../components/KMaskTextField';
import KSearchField from '../../components/KSearchfield/index';
import cidadeRepositorio from '../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../repositorios/representada';
import { ISnack } from '../../Types';


const ListaRepresentada = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [representada, setRepresentada] = useState<any>({})
    const [representadas, setRepresentadas] = useState<Array<any>>([]);
    const [cidades, setCidades] = useState<Array<object>>([]);
    const [cidade, setCidade] = useState<any>(null);
    const [estado, setEstado] = useState<any>(null);
    const [estados, setEstados] = useState<Array<object>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }

    useEffect(() => {
        setEstado(representada.idrepresentada ? {
            value: representada.idestado,
            label: representada.estado.nome
        } : null)
        setCidade(representada.idrepresentada ? {
            value: representada.idcidade,
            label: representada.cidade.nome
        } : null)
    }, [representada.idrepresentada])

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepresentada({
            ...representada,
            [event.target.name]: event.target.value
        })
    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepresentada({
            ...representada,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setRepresentada({
            ...representada,
            [campo]: valor
        })
    }

    const handleValidacoes = () => {
        if (!representada.nome) {
            return {
                type: 'error',
                message: 'Nome não pode ser vazio'
            }
        }
        if (!representada.cnpjcpf) {
            return {
                type: 'error',
                message: 'Cnpj não pode ser vazio'
            }
        }
        if (!cidade) {
            return {
                type: 'error',
                message: 'Cidade não pode ser vazio'
            }
        }
        if (!estado) {
            return {
                type: 'error',
                message: 'Estado não pode ser vazio'
            }
        }
        if (!representada.ie) {
            return {
                type: 'error',
                message: 'Inscrição Estadual não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    //TODO fazer campo de máscara e responsividade na tabela e floatbutton

    const loadRepresentadas = async () => {
        try {
            setIsLoading(true);
            const listaRepresentadas = await representadaRepositorio.getAll();
            const cidades = await cidadeRepositorio.getSearchField();
            const estados = await estadoRepositorio.getSearchField();

            setCidades(cidades);
            setEstados(estados);
            setRepresentadas(listaRepresentadas.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadRepresentadas();
        })()
    }, [])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/representadas')}>Representadas</Link>
                </Breadcrumbs>
                <Typography variant="h5">Representadas</Typography>

                <KTable
                    editable
                    title="Representadas"
                    searchBar
                    searchBarItems={["idrepresentada", "nome"]}
                    cadastro
                    item={representada}
                    setItem={setRepresentada}
                    iditem="idrepresentada"
                    route="representada"
                    loadItems={loadRepresentadas}
                    itemsHeader={["Id", "Nome"]}
                    itemsName={["idrepresentada", "nome"]}
                    items={representadas}
                    setItems={setRepresentadas}
                    validator={handleValidacoes}
                    maxWidth="md"
                >

                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox onChange={handleChangedCheckbox} name="ativo" checked={representada.ativo} color="primary" />
                            <Typography>Ativo</Typography>
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Nome"
                                name="nome"
                                value={representada.nome || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KMaskTextField
                                label="CNPJ"
                                mask="99.999.999/9999-99"
                                name="cnpjcpf"
                                value={representada.cnpjcpf || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="IE"
                                name="ie"
                                value={representada.ie || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="IM"
                                name="im"
                                value={representada.im || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Endereço"
                                name="endereco"
                                value={representada.endereco || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: cidades, value: cidade, setValue: setCidade, label: 'Cidade' }}
                                name="idcidade"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={!responsive ? 4 : 12}>
                            <KSearchField
                                {...{ itens: estados, value: estado, setValue: setEstado, label: 'Estado' }}
                                name="idestado"
                                onChange={handleChangeAuto}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 4 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Bairro"
                                name="bairro"
                                value={representada.bairro || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 2 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Complemento"
                                name="complemento"
                                value={representada.complemento || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 2 : 12}>
                            <KMaskTextField
                                mask="99999-999"
                                label="Cep"
                                name="cep"
                                value={representada.cep || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 3 : 12}>
                            <KMaskTextField
                                mask="(99)9999-9999"
                                label="Fone"
                                name="fone"
                                value={representada.fone || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 2 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Ramal"
                                name="ramal"
                                value={representada.ramal || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 3 : 12}>
                            <KMaskTextField
                                mask="(99)99999-9999"
                                label="Celular"
                                name="fax"
                                value={representada.fax || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 4 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Contato"
                                name="contato"
                                value={representada.contato || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Email"
                                name="email"
                                value={representada.email || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                    </Grid>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaRepresentada;