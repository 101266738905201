import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IMarcaRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const marcaRepositorio: IMarcaRepositorio = {
    getAll: async () => {
        const response = await api.get('/marca', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idmarca: number) => {
        const response = await api.get('/marca/' + idmarca, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtomarca: any) => {
        const response = await api.post('/marca', dtomarca, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtomarca: any) => {
        const response = await api.put('/marca/' + dtomarca.idmarca, dtomarca, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/marca', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });

        let listaSearchField = response.data.map((item: any) => {
            return {
                value: item.idmarca,
                label: item.descricao
            }
        })

        return listaSearchField;
    }
}

export default marcaRepositorio;