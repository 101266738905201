import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme, Tab, AppBar, Button, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PDFViewer } from '@react-pdf/renderer';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import KSelect from '../../components/KSelect';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import KDialogCadastro from '../../components/KDialogCadastro';
import KSearchField from '../../components/KSearchfield/index';
import cidadeRepositorio from '../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../repositorios/representada';
import tipoPagamentoRepositorio from '../../repositorios/tipoPagamento';
import marcaRepositorio from '../../repositorios/marca';
import unidadeRepositorio from '../../repositorios/unidade';
import produtoGrupoRepositorio from '../../repositorios/produtoGrupo';
import produtoRepositorio from '../../repositorios/produto';
import clienteRepositorio from '../../repositorios/cliente';
import KDatePicker from '../../components/KDatePicker/index.js';

import './Report/styles.css';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import MyDocument from './Report/index';
import { PrintOutlined } from '@material-ui/icons';
import KMaskTextField from '../../components/KMaskTextField';
import { ISnack } from '../../Types';

const ListaCliente = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [tab, setTab] = useState('1');
    const [initialCliente, setInitialCliente] = useState({
        datacadastro: new Date(),
        tipopessoa: 2
    })
    const [cliente, setCliente] = useState<any>({
        datacadastro: new Date(),
        tipopessoa: 2
    })
    const [clientes, setClientes] = useState<Array<any>>([]);
    const [cidades, setCidades] = useState<Array<any>>([]);
    const [estados, setEstados] = useState<Array<any>>([]);

    const [cidadePrincipal, setCidadePrincipal] = useState<any>(null);
    const [estadoPrincipal, setEstadoPrincipal] = useState<any>(null);

    const [cidadeEntrega, setCidadeEntrega] = useState<any>(null);
    const [estadoEntrega, setEstadoEntrega] = useState<any>(null);

    const [cidadeCobranca, setCidadeCobranca] = useState<any>(null);
    const [estadoCobranca, setEstadoCobranca] = useState<any>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {

        setCliente({
            ...cliente,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setCliente({
                ...cliente,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCliente({
            ...cliente,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setCliente({
            ...cliente,
            [campo]: valor
        })
    }
    const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCliente({
            ...cliente,
            [event.target.name]: event.target.value
        })
    }

    const handleValidacoes = () => {
        if (!cliente.nome) {
            return {
                type: 'error',
                message: 'Nome não pode ser vazio'
            }
        }
        if (!cliente.tipopessoa) {
            return {
                type: 'error',
                message: 'Tipo de Pessoa não pode ser vazio'
            }
        }
        if (!cliente.razaosocial) {
            return {
                type: 'error',
                message: 'Razão Social não pode ser vazio'
            }
        }
        if (!cliente.cnpjcpf) {
            return {
                type: 'error',
                message: `${cliente.tipopessoa === 1 ? 'CPF' : cliente.tipopessoa === 2 ? 'CNPJ' : 'CNPJCPF'} não pode ser vazio`
            }
        }
        if (!cliente.rg && cliente.tipopessoa === 1) {
            return {
                type: 'error',
                message: 'RG não pode ser vazio'
            }
        }
        if (!cliente.inscprodutor && cliente.tipopessoa === 1) {
            return {
                type: 'error',
                message: 'Inscrição de produtor não pode ser vazio'
            }
        }
        if (!cliente.ie && cliente.tipopessoa === 2) {
            return {
                type: 'error',
                message: 'IE não pode ser vazio'
            }
        }
        if (!cliente.im && cliente.tipopessoa === 2) {
            return {
                type: 'error',
                message: 'IM não pode ser vazio'
            }
        }
        if (!cidadePrincipal) {
            return {
                type: 'error',
                message: 'Cidade não pode ser vazia'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    const loadClientes = async () => {
        try {
            setIsLoading(true);
            const listaCliente = await clienteRepositorio.getAll();
            const listaCidadePrincipal = await cidadeRepositorio.getSearchField();
            const listaEstadoPrincipal = await estadoRepositorio.getSearchField();

            setCidades(listaCidadePrincipal);
            setEstados(listaEstadoPrincipal);
            setClientes(listaCliente.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadClientes();
        })()
    }, [])

    useEffect(() => {
        setCidadePrincipal(cliente.idcliente ? cidades.find((item: any) => item.value == cliente.idcidadeprincipal) : null);
        setEstadoPrincipal(cliente.idcliente ? estados.find((item: any) => item.value == cliente.idestadoprincipal) : null);
        setCidadeEntrega(cliente.idcliente ? cidades.find((item: any) => item.value == cliente.idcidadeentrega) : null);
        setEstadoEntrega(cliente.idcliente ? estados.find((item: any) => item.value == cliente.idestadoentrega) : null);
        setCidadeCobranca(cliente.idcliente ? cidades.find((item: any) => item.value == cliente.idcidadecobranca) : null);
        setEstadoCobranca(cliente.idcliente ? estados.find((item: any) => item.value == cliente.idestadocobranca) : null);
    }, [cliente.idcliente])

    return (
        <React.Fragment>
            <KAppBar>
                <div>
                    <KLoader isLoading={isLoading} />
                    <KSnackbar
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                    />
                    <Breadcrumbs separator=">">
                        <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                        <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/clientes')}>Clientes</Link>
                    </Breadcrumbs>
                    <Typography variant="h5">Clientes</Typography>
                    <KTable
                        report
                        reportName="clientes"
                        editable
                        title="Cliente"
                        searchBar
                        maxWidth="md"
                        searchBarItems={["idcliente", "nome"]}
                        cadastro
                        item={cliente}
                        setItem={setCliente}
                        iditem="idcliente"
                        route="cliente"
                        loadItems={loadClientes}
                        itemsHeader={["Id", "Nome"]}
                        itemsName={["idcliente", "nome"]}
                        items={clientes}
                        setItems={setClientes}
                        validator={handleValidacoes}
                        noPadding={true}
                        initialState={initialCliente}
                    >
                        <TabContext value={tab}>
                            <AppBar position="static">
                                <TabList variant="scrollable" style={{ color: 'white' }} scrollButtons="on" onChange={(e, newValue: string) => setTab(newValue)}>
                                    <Tab style={{ color: 'white' }} label="Geral" value="1"></Tab>
                                    <Tab style={{ color: 'white' }} label="Endereço" value="2"></Tab>
                                </TabList>
                            </AppBar>
                            <TabPanel value="1">
                                <Grid container spacing={2} item xs={12}>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox onChange={handleChangedCheckbox} name="ativo" checked={cliente.ativo} color="primary" />
                                        <Typography>Ativo</Typography>
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KDatePicker disabled date={cliente.datacadastro} onChange={handleChangeAuto} name="datacadastro" />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <KSelect {...{ itens: [{ value: 1, label: 'Física' }, { value: 2, label: 'Jurídica' }], value: cliente.tipopessoa, label: 'Tipo Pessoa' }} name="tipopessoa" onChange={handleChangedNumber} />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Nome"
                                            name="nome"
                                            value={cliente.nome || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Razão Social"
                                            name="razaosocial"
                                            value={cliente.razaosocial || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive && cliente.tipopessoa ? 6 : 12}>
                                        <KMaskTextField
                                            mask={cliente.tipopessoa === 1 ? "999.999.999-99" : "99.999.999/9999-99"}
                                            label={cliente.tipopessoa === 1 ? "CPF" : "CNPJ"}
                                            name="cnpjcpf"
                                            value={cliente.cnpjcpf || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    {cliente.tipopessoa === 1 ? (
                                        <Grid item xs={!responsive ? 6 : 12}>
                                            <KMaskTextField
                                                mask="9.999.999"
                                                label="RG"
                                                name="rg"
                                                value={cliente.rg || ''}
                                                onChange={handleChanged}
                                            />
                                        </Grid>
                                    ) : null}
                                    {cliente.tipopessoa === 2 ? (
                                        <Grid item xs={!responsive ? 6 : 12}>
                                            <KMaskTextField
                                                mask="999.999.999"
                                                label="IE"
                                                name="ie"
                                                value={cliente.ie || ''}
                                                onChange={handleChanged}
                                            />
                                        </Grid>
                                    ) : null}
                                    {cliente.tipopessoa === 2 ? (
                                        <Grid item xs={!responsive ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                margin="dense"
                                                label="IM"
                                                name="im"
                                                value={cliente.im || ''}
                                                onChange={handleChanged}
                                            />
                                        </Grid>
                                    ) : null}
                                    {cliente.tipopessoa === 1 ? (
                                        <Grid item xs={!responsive ? 6 : 12}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                margin="dense"
                                                label="Insc. Produtor"
                                                name="inscprodutor"
                                                value={cliente.inscprodutor || ''}
                                                onChange={handleChanged}
                                            />
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Obs"
                                            name="obs"
                                            value={cliente.obs || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="2">
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Endereço"
                                            name="enderecoprincipal"
                                            value={cliente.enderecoprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: cidades, value: cidadePrincipal, setValue: setCidadePrincipal, label: 'Cidade' }}
                                            name="idcidadeprincipal"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: estados, value: estadoPrincipal, setValue: setEstadoPrincipal, label: 'Estado' }}
                                            name="idestadoprincipal"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KMaskTextField
                                            mask="99999-999"
                                            label="Cep"
                                            name="cepprincipal"
                                            value={cliente.cepprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Bairro"
                                            name="bairroprincipal"
                                            value={cliente.bairroprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Complemento"
                                            name="complementoprincipal"
                                            value={cliente.complementoprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)9999-9999"
                                            label="Fone"
                                            name="foneprincipal"
                                            value={cliente.foneprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 2 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Ramal"
                                            name="ramalprincipal"
                                            value={cliente.ramalprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)99999-9999"
                                            label="Celular"
                                            name="faxprincipal"
                                            value={cliente.faxprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Email"
                                            name="emailprincipal"
                                            value={cliente.emailprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Contato"
                                            name="contatoprincipal"
                                            value={cliente.contatoprincipal || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                </Grid>
                            </TabPanel>
                            <TabPanel value="3">
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Endereço"
                                            name="enderecoentrega"
                                            value={cliente.enderecoentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: cidades, value: cidadeEntrega, setValue: setCidadeEntrega, label: 'Cidade' }}
                                            name="idcidadeentrega"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: estados, value: estadoEntrega, setValue: setEstadoEntrega, label: 'Estado' }}
                                            name="idestadoentrega"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KMaskTextField
                                            mask="99999-999"
                                            label="Cep"
                                            name="cepentrega"
                                            value={cliente.cepentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Bairro"
                                            name="bairroentrega"
                                            value={cliente.bairroentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Complemento"
                                            name="complementoentrega"
                                            value={cliente.complementoentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)99999-9999"
                                            label="Fone"
                                            name="foneentrega"
                                            value={cliente.foneentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 2 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Ramal"
                                            name="ramalentrega"
                                            value={cliente.ramalentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)99999-9999"
                                            label="Fax"
                                            name="faxentrega"
                                            value={cliente.faxentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Email"
                                            name="emailentrega"
                                            value={cliente.emailentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Contato"
                                            name="contatoentrega"
                                            value={cliente.contatoentrega || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="4">
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Endereço"
                                            name="enderecocobranca"
                                            value={cliente.enderecocobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: cidades, value: cidadeCobranca, setValue: setCidadeCobranca, label: 'Cidade' }}
                                            name="idcidadecobranca"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            {...{ itens: estados, value: estadoCobranca, setValue: setEstadoCobranca, label: 'Estado' }}
                                            name="idestadocobranca"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KMaskTextField
                                            mask="99999-999"
                                            label="Cep"
                                            name="cepcobranca"
                                            value={cliente.cepcobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Bairro"
                                            name="bairrocobranca"
                                            value={cliente.bairrocobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Complemento"
                                            name="complementocobranca"
                                            value={cliente.complementocobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)9999-9999"
                                            label="Fone"
                                            name="fonecobranca"
                                            value={cliente.fonecobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 2 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Ramal"
                                            name="ramalcobranca"
                                            value={cliente.ramalcobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 5 : 12}>
                                        <KMaskTextField
                                            mask="(99)99999-9999"
                                            label="Celular"
                                            name="faxcobranca"
                                            value={cliente.faxcobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>

                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Email"
                                            name="emailcobranca"
                                            value={cliente.emailcobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="dense"
                                            label="Contato"
                                            name="contatocobranca"
                                            value={cliente.contatocobranca || ''}
                                            onChange={handleChanged}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>

                    </KTable>
                </div>
            </KAppBar>
        </React.Fragment>
    )
}

export default ListaCliente;