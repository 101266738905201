import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IProdutoRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const produtoRepositorio: IProdutoRepositorio = {
    getAll: async () => {
        const response = await api.get('/produto', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idproduto: number) => {
        const response = await api.get('/produto/' + idproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtoproduto: any) => {
        const response = await api.post('/produto', dtoproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtoproduto: any) => {
        const response = await api.put('/produto/' + dtoproduto.idproduto, dtoproduto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/produto', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        const listaProdutos = response.data.map((item: any) => {
            return {
                label: item.descricao,
                value: item.idproduto,
                idunidade: item.idunidade
            }
        })
        return listaProdutos;
    }
}

export default produtoRepositorio;