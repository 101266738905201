import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface ITransportadoraRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const transportadoraRepositorio: ITransportadoraRepositorio = {
    getAll: async () => {
        const response = await api.get('/transportadora', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idtransportadora: number) => {
        const response = await api.get('/transportadora/' + idtransportadora, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtoTransportadora: any) => {
        const response = await api.post('/transportadora', dtoTransportadora, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtoTransportadora: any) => {
        const response = await api.put('/transportadora/' + dtoTransportadora.idtransportadora, dtoTransportadora, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/transportadora', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        let listaSearchField: any = [] ; 
        
        response.data.map((item: any) => {
            if(item.ativo) {
                listaSearchField.push({
                    value: item.idtransportadora,
                    label: item.nome
                })
            }
        });

        return listaSearchField;

    }
}

export default transportadoraRepositorio;