import { createMuiTheme } from '@material-ui/core/styles';

const ThemeApp = createMuiTheme({
    palette: {
        primary: {
            main: '#00A9E0',
            dark: '#039BCC'
        },
        secondary: {
            main: '#ffffff'
        }
    }
})

export default ThemeApp;