import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IRepresentadaRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const representadaRepositorio: IRepresentadaRepositorio = {
    getAll: async () => {
        const response = await api.get('/representada', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idrepresentada: number) => {
        const response = await api.get('/representada/' + idrepresentada, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtorepresentada: any) => {
        const response = await api.post('/representada', dtorepresentada, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtorepresentada: any) => {
        const response = await api.put('/representada/' + dtorepresentada.idrepresentada, dtorepresentada, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/representada', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        let listaSearchField: any = [] ; 
        
        response.data.map((item: any) => {
            if(item.ativo) {
                listaSearchField.push({
                    value: item.idrepresentada,
                    label: item.nome
                })
            }
        });

        return listaSearchField;

    }
}

export default representadaRepositorio;