import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IProdutoGrupo {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const produtoGrupoRepositorio: IProdutoGrupo = {
    getAll: async () => {
        const response = await api.get('/produtogrupo', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idprodutogrupo: number) => {
        const response = await api.get('/produtogrupo/' + idprodutogrupo, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtoprodutogrupo: any) => {
        const response = await api.post('/produtogrupo', dtoprodutogrupo, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtoprodutogrupo: any) => {
        const response = await api.put('/produtogrupo/' + dtoprodutogrupo.idprodutogrupo, dtoprodutogrupo, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/produtogrupo', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        
        let listaSearchField = response.data.map((item: any) => {
            return {
                value: item.idprodutogrupo,
                label: item.descricao
            }
        });

        return listaSearchField;
    }

}

export default produtoGrupoRepositorio;