import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IUnidadeRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
    getSearchFieldEmbalagem: Function
}

const unidadeRepositorio: IUnidadeRepositorio = {
    getAll: async () => {
        const response = await api.get('/unidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idunidade: number) => {
        const response = await api.get('/unidade/' + idunidade, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtounidade: any) => {
        const response = await api.post('/unidade', dtounidade, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtounidade: any) => {
        const response = await api.put('/unidade/' + dtounidade.idunidade, dtounidade, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/unidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        let listaSearchField = response.data.map((item: any) => {
            return {
                value: item.idunidade,
                label: `${item.descricao} - ${String(item.fator)}`,
                fator: item.fator
            }
        })

        return listaSearchField;
    },
    getSearchFieldEmbalagem: async () => {
        const response = await api.get('/unidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        let listaSearchField = response.data.map((item: any) => {
            return {
                value: item.idunidade,
                label: `${item.descricao}`,
                fator: item.fator
            }
        })

        return listaSearchField;
    }
}

export default unidadeRepositorio;