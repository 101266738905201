import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import AllInboxOutlinedIcon from '@material-ui/icons/AllInboxOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import GroupOutlined from '@material-ui/icons/GroupOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import TurnedInNotOutlinedIcon from '@material-ui/icons/TurnedInNotOutlined';
import { AppBar, Toolbar, Typography, useScrollTrigger, IconButton, Hidden, useMediaQuery, useTheme, Drawer, Container, Box, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ExitToAppOutlined, Menu } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Cookie from 'universal-cookie';

import usuarioRepositorio from '../../repositorios/usuario/usuarioRepositorio';
import KLoader from '../KLoader';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            zIndex: 1100
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: '100vw'
            // flexGrow: 1,
            // padding: theme.spacing(3),
        },
        drawerPaper: {
            width: drawerWidth,
        },
    }),
);

function ElevationScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const KAppBar = (props: Props) => {
    const theme = useTheme();
    const cookies = new Cookie();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { window } = props;
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [mobileOpen, setMobileOpen] = useState<boolean>(false);

    const onClickSair = () => {
        cookies.remove('token');
        cookies.remove('sessionId');
        history.push('/');
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const container = window !== undefined ? () => window().document.body : undefined;

    const drawer = (
        <List>
            <ListItem button onClick={() => history.push('/pedidosvenda')}>
                <ListItemIcon>{<ShoppingCartOutlined style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Pedido Venda" />
            </ListItem>
            <ListItem button onClick={() => history.push('/clientes')}>
                <ListItemIcon>{<GroupOutlined style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem button onClick={() => history.push('/transportadoras')}>
                <ListItemIcon>{<LocalShippingOutlined style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Transportadoras" />
            </ListItem>
            <ListItem button onClick={() => history.push('/representadas')}>
                <ListItemIcon>{<BusinessOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Representadas" />
            </ListItem>
            <ListItem button onClick={() => history.push('/tipospagamento')}>
                <ListItemIcon>{<PaymentOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Cond. de Pagamento" />
            </ListItem>
            <ListItem button onClick={() => history.push('/gruposproduto')}>
                <ListItemIcon>{<AccountTreeOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Grupos de Produto" />
            </ListItem>
            <ListItem button onClick={() => history.push('/unidades')}>
                <ListItemIcon>{<FormatListNumberedOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Unidades" />
            </ListItem>
            <ListItem button onClick={() => history.push('/marcas')}>
                <ListItemIcon>{<TurnedInNotOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Marcas" />
            </ListItem>
            <ListItem button onClick={() => history.push('/produtos')}>
                <ListItemIcon>{<AllInboxOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Produtos" />
            </ListItem>
            <ListItem button onClick={() => history.push('/usuarios')}>
                <ListItemIcon>{<AccountCircleOutlinedIcon style={{ color: theme.palette.primary.main }} />}</ListItemIcon>
                <ListItemText primary="Usuários" />
            </ListItem>
        </List>
    )

    return (
        <div className={classes.root}>
            <CssBaseline />
            <KLoader isLoading={isLoading} />
            <ElevationScroll {...props}>
                <AppBar
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: !responsive ? open : false,
                    })}
                >
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={!responsive ? handleDrawerOpen : handleDrawerToggle}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: !responsive ? open : false,
                                })}
                            >
                                <Menu style={{ color: theme.palette.secondary.main }} />
                            </IconButton>
                            <Typography style={{ color: theme.palette.secondary.main }}>KG Representação de Cereais LTDA.</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Tooltip title="Sair">
                                <IconButton onClick={onClickSair}>
                                    <ExitToAppOutlined style={{ color: theme.palette.secondary.main }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <React.Fragment>
                <Hidden smDown implementation="css">
                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open,
                            }),
                        }}
                    >
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <Divider />
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>

                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    <Container>
                        <Box mt={1} style={{ display: 'flex', flexDirection: 'column' }}>
                            {props.children}
                        </Box>
                    </Container>
                </main>
            </React.Fragment>
        </div>
    )
}

export default KAppBar;