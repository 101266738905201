import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    //height: 200,
    // transform: 'translateZ(200px)',
    // flexGrow: 1,
  },
  backdrop: {
    marginBottom: theme.spacing(7)
  },

  speedDial: {
    zIndex: 1000,
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    '& .MuiFab-primary': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main
    },
  },
}));