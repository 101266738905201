import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import transportadoraRepositorio from '../../repositorios/transportadora/transportadoraRepositorio';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import KDialogCadastro from '../../components/KDialogCadastro';
import KSearchField from '../../components/KSearchfield/index';
import cidadeRepositorio from '../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../repositorios/representada';
import tipoPagamentoRepositorio from '../../repositorios/tipoPagamento';
import marcaRepositorio from '../../repositorios/marca';
import unidadeRepositorio from '../../repositorios/unidade';
import produtoGrupoRepositorio from '../../repositorios/produtoGrupo';
import produtoRepositorio from '../../repositorios/produto';
import { ISnack } from '../../Types';


const ListaProduto = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [produto, setProduto] = useState<any>({})
    const [produtos, setProdutos] = useState<Array<any>>([]);

    const [produtoGrupo, setProdutoGrupo] = useState<any>(null);
    const [produtoGrupos, setProdutoGrupos] = useState<Array<any>>([]);

    const [representada, setRepresentada] = useState<any>(null);
    const [representadas, setRepresentadas] = useState<Array<any>>([]);

    const [marca, setMarca] = useState<any>(null);
    const [marcas, setMarcas] = useState<Array<any>>([]);

    const [unidade, setUnidade] = useState<any>(null);
    const [unidades, setUnidades] = useState<Array<any>>([]);

    const [embalagem, setEmbalagem] = useState<any>(null);
    const [embalagens, setEmbalagens] = useState<Array<any>>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProduto({
            ...produto,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setProduto({
                ...produto,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProduto({
            ...produto,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setProduto({
            ...produto,
            [campo]: valor
        })
    }

    const handleValidacoes = () => {
        if (!produto.descricao) {
            return {
                type: 'error',
                message: 'Descrição não pode ser vazio'
            }
        }
        if (!produto.valor || isNaN(produto.valor)) {
            return {
                type: 'error',
                message: 'Valor deve ser apenas numeros e não pode ser vazio'
            }
        }
        if (!unidade) {
            return {
                type: 'error',
                message: 'Unidade não pode ser vazio'
            }
        }
        if (!produtoGrupo) {
            return {
                type: 'error',
                message: 'Grupo não pode ser vazio'
            }
        }
        if (!marca) {
            return {
                type: 'error',
                message: 'Marca não pode ser vazio'
            }
        }
        if (!embalagem) {
            return {
                type: 'error',
                message: 'Embalagem não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    //TODO fazer campo de máscara e responsividade na tabela e floatbutton

    const loadProdutos = async () => {
        try {
            setIsLoading(true);
            const listaProduto = await produtoRepositorio.getAll();
            const listaMarca = await marcaRepositorio.getSearchField();
            const listaUnidade = await unidadeRepositorio.getSearchField();
            const listaEmbalagem = await unidadeRepositorio.getSearchFieldEmbalagem();
            const listaProdutoGrupo = await produtoGrupoRepositorio.getSearchField();
            const listaRepresentada = await representadaRepositorio.getSearchField();


            setMarcas(listaMarca);
            setUnidades(listaUnidade);
            setEmbalagens(listaEmbalagem);
            setProdutoGrupos(listaProdutoGrupo);
            setRepresentadas(listaRepresentada);
            setProdutos(listaProduto.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadProdutos();
        })()
    }, [])

    useEffect(() => {
        setMarca(produto.idproduto ? marcas.find((item: any) => item.value == produto.idmarca) : null);
        setUnidade(produto.idproduto ? unidades.find((item: any) => item.value == produto.idunidade) : null);
        setEmbalagem(produto.idproduto ? embalagens.find((item: any) => item.value == produto.idembalagem) : null);
        setProdutoGrupo(produto.idproduto ? produtoGrupos.find((item: any) => item.value == produto.idprodutogrupo) : null);
        setRepresentada(produto.idproduto ? representadas.find((item: any) => item.value == produto.idrepresentada) : null);
    }, [produto.idproduto])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/produtos')}>Produtos</Link>
                </Breadcrumbs>
                <Typography variant="h5">Produtos</Typography>

                <KTable
                    editable
                    title="Produto"
                    searchBar
                    maxWidth="sm"
                    searchBarItems={["idproduto", "descricao"]}
                    cadastro
                    item={produto}
                    setItem={setProduto}
                    iditem="idproduto"
                    route="produto"
                    loadItems={loadProdutos}
                    itemsHeader={["Id", "Descrição"]}
                    itemsName={["idproduto", "descricao"]}
                    items={produtos}
                    setItems={setProdutos}
                    validator={handleValidacoes}
                >

                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox onChange={handleChangedCheckbox} name="ativo" checked={produto.ativo} color="primary" />
                            <Typography>Ativo</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Descrição"
                                name="descricao"
                                value={produto.descricao || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="number"
                                margin="dense"
                                label="Valor"
                                name="valor"
                                value={produto.valor || ''}
                                onChange={handleChangedNumber}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: produtoGrupos, value: produtoGrupo, setValue: setProdutoGrupo, label: 'Grupo' }}
                                name="idprodutogrupo"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: representadas, value: representada, setValue: setRepresentada, label: 'Representada' }}
                                name="idrepresentada"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: marcas, value: marca, setValue: setMarca, label: 'Marca' }}
                                name="idmarca"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: unidades, value: unidade, setValue: setUnidade, label: 'Unidade' }}
                                name="idunidade"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: embalagens, value: embalagem, setValue: setEmbalagem, label: 'Embalagem' }}
                                name="idembalagem"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                    </Grid>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaProduto;