import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { AppBar, Dialog, DialogActions, DialogContent, Grid, IconButton, Table, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import currencyFormatter from 'currency-formatter';

import Logo from '../../../../images/Logo.png';
import { Close } from '@material-ui/icons';

// Create styles
const styles = StyleSheet.create<any>({
    page: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingHorizontal: 5
    },
    section: {
        flexDirection: 'column',
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    sectionBorder: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        padding: 10,
        width: '50%',
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    sectionTitle: {
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: 20,
    },
    sectionMessage: {
        flexDirection: 'column',
        flexGrow: 1,
        margin: 5,
        padding: 5,
    },
    col: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    containerBorder: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        fontSize: 8,
        color: 'grey',
    },
    title: {
        fontSize: 14,
        color: 'grey',
    },
    image: {
        width: 111,
        height: 56
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableWithoutBorder: {
        display: "table",
        width: "auto",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol40: {
        width: "40%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColFooter: {
        flexGrow: 1,
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableColHeader: {
        flexGrow: 1,
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableColWithoutBorder: {
        marginBottom: 5,
        width: "20%"
    },
    tableColWithoutBorder40: {
        marginBottom: 5,
        width: "40%"
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8
    },
    tableCellFooter: {
        marginTop: 5,
        fontSize: 8
    },
    tableCellHeader: {
        marginTop: 5,
        fontSize: 12
    },

});

interface IOrdemCarregamentoReport {
    open: boolean
    setOpen: Function
    ordem: any
    pedidoVenda: any
    index: number
}

// Create Document Component
const OrdemCarregamentoReport = (props: IOrdemCarregamentoReport) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [relatorio, setRelatorio] = useState<boolean>(false);

    const getFormatDefault = (value: number) => {
        return currencyFormatter.format(value, {
            symbol: '',
            decimal: ',',
            thousand: '.',
            precision: 3,
            format: '%v' // %s is the symbol and %v is the value
        });
    }

    useEffect(() => {
        setTimeout(() => {
            setRelatorio(true);
        }, 1);
    }, [])

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="md"
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar style={{
                        background: '#646464',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{ color: '#fff' }}>Impressão da Ordem de Carregamento</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <Close style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        minHeight: 600,
                        maxHeight: 600
                    }}
                >
                    <Grid container item xs={12} style={{height: '580px'}}>
                        <Grid item xs={12} style={{height: '100%'}}>
                            {relatorio ? (
                                <PDFViewer>
                                    <Document title="Ordem_Carregamento_KG">
                                        <Page size="A4" style={styles.page}>
                                            <View style={styles.col} fixed>
                                                <View style={styles.container}>
                                                    <View style={styles.section}>
                                                        <Image
                                                            style={styles.image}
                                                            src={Logo}
                                                        />
                                                    </View>
                                                    <View style={styles.section}>
                                                        <Text style={styles.header}>KG Representação de Cereais LTDA.</Text>
                                                        <Text style={styles.header}>Rua Professor Arlindo Junkes, 195 CEP: 88850-000  Centro</Text>
                                                        <Text style={styles.header}>Cidade: Forquilhinha/SC Fone: 48 3463 3777 Fax: 48 3463-3777</Text>
                                                        <Text style={styles.header}>CNPJ: 13.794.814/0001-97</Text>
                                                    </View>
                                                    <View style={styles.section}>
                                                        <Text style={styles.header}>{`Data: ${new Date().toLocaleString().substr(0, 16)}`}</Text>
                                                        <Text style={styles.header} render={({ pageNumber, totalPages }) => (
                                                            `Página ${pageNumber} de ${totalPages}`
                                                        )} fixed />
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionTitle}>
                                                    <View style={styles.tableColHeader}>
                                                        <Text style={styles.tableCellHeader}>Impressão de Ordem de Carregamento</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Á</Text>
                                                    <Text style={styles.header}>{props.pedidoVenda.representada.nome}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Att. Sr(a) {props.ordem.aoscuidadosde}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Referente ao Pedido Nº {props.pedidoVenda.idpedidovendarepresentada} de {props.pedidoVenda.cliente.nome}</Text>
                                                    <Text style={styles.header}>Em nome de {props.pedidoVenda.emNomeDe.nome}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Segue os dados para o carregamento:</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table}>
                                                {/* TableHeader */}
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Nº Ordem</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Placa</Text>
                                                    </View>
                                                    <View style={styles.tableCol40}>
                                                        <Text style={styles.tableCell}>Motorista</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Peso (Unidade)</Text>
                                                    </View>
                                                </View>
                                                {/* TableContent */}
                                            </View>
                                            <View style={styles.tableWithoutBorder}>
                                                <View style={styles.tableRow} key={String(props.ordem.idpedidovendacarregamento)}>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{props.index}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{props.ordem.placa}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder40}>
                                                        <Text style={styles.tableCell}>{props.ordem.motorista}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{getFormatDefault(props.ordem.peso)}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            {props.ordem.obs ? (
                                                <View style={styles.container}>
                                                    <View style={styles.section}>
                                                        <Text style={styles.header}>{`Obs: ${props.ordem.obs}`}</Text>
                                                    </View>
                                                </View>
                                            ) : null}
                                            <View style={styles.container}>
                                                <View style={styles.section}>
                                                    <Text style={styles.header}>Atenciosamente,</Text>
                                                </View>
                                            </View>
                                            <View style={styles.containerBorder}>
                                                <View style={styles.sectionBorder}>
                                                    <Text style={styles.header}>KG Representação de Cereais LTDA.</Text>
                                                </View>
                                            </View>
                                            <View fixed style={styles.tableColFooter}>
                                                <Text style={styles.tableCellFooter}>KG Representação de Cereais LTDA.</Text>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>
                            ) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
};

export default OrdemCarregamentoReport;