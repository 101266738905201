import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { AppBar, Dialog, DialogActions, DialogContent, Grid, IconButton, Table, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import currencyFormatter from 'currency-formatter';
import extenso from 'extenso';

import Logo from '../../../images/Logo.png';
import { Close } from '@material-ui/icons';
import cidadeRepositorio from '../../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../../repositorios/estado/estadoRepositorio';

// Create styles
const styles = StyleSheet.create<any>({
    page: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingHorizontal: 30
    },
    spacing: {
        marginTop: 5,
        marginBottom: 5
    },
    section: {
        flexDirection: 'column',
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    sectionBorder: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        padding: 10,
        width: '50%',
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    sectionTitle: {
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: 20,
    },
    sectionMessage: {
        flexDirection: 'column',
        flexGrow: 1,
        margin: 5,
        padding: 5,
    },
    col: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },
    containerBorder: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    header: {
        fontSize: 8,
        color: '#000',
    },
    title: {
        fontSize: 14,
        color: 'grey',
    },
    image: {
        width: 111,
        height: 56
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        padding: '8px 25px'
    },
    tableParcelas: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableWithoutBorder: {
        display: "table",
        width: "auto",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "33%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol40: {
        width: "33%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColFooter: {
        flexGrow: 1,
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        marginBottom: 5
    },
    tableColHeader: {
        flexGrow: 1,
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableColWithoutBorder: {
        marginBottom: 5,
        width: "33%"
    },
    tableColWithoutBorder40: {
        marginBottom: 5,
        width: "33%"
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8
    },
    tableCellFooter: {
        marginTop: 5,
        fontSize: 8,
        marginBottom: 10
    },
    tableCellHeader: {
        marginTop: 5,
        fontSize: 12
    },

});

interface IPedidoVendaReport {
    open: boolean
    setOpen: Function
    pedidoVenda: any
    unidades: Array<any>
    tiposPagamento: Array<any>

}

// Create Document Component
const PedidoVendaReport = (props: IPedidoVendaReport) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [relatorio, setRelatorio] = useState<boolean>(false);
    const [cidades, setCidades] = useState<Array<any>>([]);
    const [estados, setEstados] = useState<Array<any>>([]);

    const getFormatDefault = (value: number) => {
        return currencyFormatter.format(value, {
            symbol: '',
            decimal: ',',
            thousand: '.',
            precision: 3,
            format: '%v' // %s is the symbol and %v is the value
        });
    }

    const getUnidadeById = (id: number) => {
        let unidade = props.unidades.find(item => item.value === id);
        return unidade.label || '';
    }

    const getPagamentoById = (id: number) => {
        let tipoPagamento = props.tiposPagamento.find(item => item.value === id);
        return tipoPagamento.label || '';
    }

    const getTipoCnpjCpf = () => {
        switch (props.pedidoVenda.cliente.tipopessoa) {
            case 1:
                return 'CPF'
            case 2:
                return 'CNPJ'
            default:
                return 'Não Informado'
        }
    }

    const getTipoIEOuIP = () => {
        switch (props.pedidoVenda.cliente.tipopessoa) {
            case 1:
                return {
                    label: 'Inscrição de Produtor',
                    value: props.pedidoVenda.cliente.inscprodutor
                }
            case 2:
                return {
                    label: 'Inscrição Estadual',
                    value: props.pedidoVenda.cliente.ie
                }

            default:
                return {
                    label: 'Não informado',
                    value: 'Não informado'
                }
        }
    }

    const getCidadeById = (id: number) => {
        let cidade = cidades.find(item => item.value === id);
        return cidade.label || '';
    }

    const getEstadoById = (id: number) => {
        let estado = estados.find(item => item.value === id);
        return estado.sigla || '';
    }

    const getFrete = (id: number) => {
        switch (id) {
            case 1:
                return 'Emitente';
            case 2:
                return 'Destinatário';
            default:
                return 'Inválido';
        }
    }
    useEffect(() => {
        (async () => {
            const cidadeResponse = await cidadeRepositorio.getSearchField();
            const estadoResponse = await estadoRepositorio.getSearchField();
            setCidades(cidadeResponse);
            setEstados(estadoResponse);
            setTimeout(() => {
                setRelatorio(true);
            }, 10);

        })()
    }, [])

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="md"
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar style={{
                        background: '#646464',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{ color: '#fff' }}>Impressão do pedido de venda</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <Close style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        minHeight: 600,
                        maxHeight: 600
                    }}
                >
                    <Grid container item xs={12} style={{ height: '580px' }}>
                        <Grid item xs={12} style={{ height: '100%' }}>
                            {relatorio ? (
                                <PDFViewer>
                                    <Document title="Pedido_Venda_KG">
                                        <Page size="A4" style={styles.page}>

                                            {/* Header */}
                                            <View style={styles.col} fixed>
                                                <View style={styles.container} fixed>
                                                    <View style={styles.section} fixed>
                                                        <Image
                                                            style={styles.image}
                                                            src={Logo}
                                                        />
                                                    </View>
                                                    <View style={styles.section}>
                                                        <Text style={styles.header}>KG Representação de Cereais LTDA.</Text>
                                                        <Text style={styles.header}>Rua Professor Arlindo Junkes, 195 CEP: 88850-000  Centro</Text>
                                                        <Text style={styles.header}>Cidade: Forquilhinha/SC Fone: 48 3463 3777 Fax: 48 3463-3777</Text>
                                                        <Text style={styles.header}>CNPJ: 13.794.814/0001-97</Text>
                                                    </View>
                                                    <View style={styles.section}>
                                                        <Text style={styles.header}>{`Data: ${new Date().toLocaleString().substr(0, 16)}`}</Text>
                                                        <Text style={styles.header} render={({ pageNumber, totalPages }) => (
                                                            `Página ${pageNumber} de ${totalPages}`
                                                        )} fixed />
                                                    </View>
                                                </View>
                                                <View style={{
                                                    width: '100%',
                                                    borderWidth: 1,
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    borderTop: 0,
                                                    borderBottom: 1
                                                }}>

                                                </View>
                                            </View>
                                            {/* Content */}
                                            <View style={styles.container}>
                                                <View style={{
                                                    width: '100%',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Text style={styles.tableCellHeader}>Temos o prazer de confirmar o negócio realizado nesta data por nosso intermédio</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Confirmação Nº {props.pedidoVenda.idpedidovendarepresentada}</Text>
                                                </View>
                                            </View>
                                            {/* Representada */}
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Vendedor: {props.pedidoVenda.representada.nome}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Endereço: {props.pedidoVenda.representada.endereco}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Complemento: {props.pedidoVenda.representada.complemento}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Bairro: {props.pedidoVenda.representada.bairro}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Cidade/Estado: {`${getCidadeById(props.pedidoVenda.representada.idcidade)}/${getEstadoById(props.pedidoVenda.representada.idestado)}`}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>CEP: {props.pedidoVenda.representada.cep}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>CNPJ: {props.pedidoVenda.representada.cnpjcpf}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Inscrição Estadual: {props.pedidoVenda.representada.ie}</Text>
                                                </View>
                                            </View>
                                            {/* Cliente */}
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Cliente: {props.pedidoVenda.cliente.nome}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Endereço: {props.pedidoVenda.cliente.enderecoprincipal}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Complemento: {props.pedidoVenda.cliente.complementoprincipal}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Bairro: {props.pedidoVenda.cliente.bairroprincipal}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Cidade/Estado: {`${getCidadeById(props.pedidoVenda.cliente.idcidadeprincipal)}/${getEstadoById(props.pedidoVenda.cliente.idestadoprincipal)}`}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>CEP: {props.pedidoVenda.cliente.cepprincipal}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>{`${getTipoCnpjCpf()}:`} {props.pedidoVenda.cliente.cnpjcpf}</Text>
                                                </View>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>{`${getTipoIEOuIP().label}:`} {getTipoIEOuIP().value}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.table}>
                                                <Text style={styles.header}>Mercadoria: {props.pedidoVenda.produto.descricao}</Text>
                                            </View>
                                            {/* Produto */}
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Quantidade: {getFormatDefault(props.pedidoVenda.quantidade)}({getUnidadeById(props.pedidoVenda.produto.idunidade)})</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Prazo Retirada: {props.pedidoVenda.prazoretirada}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Local Retirada: {props.pedidoVenda.localretirada}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Preço Unitário: R${`${getFormatDefault(props.pedidoVenda.valorvenda)} (${extenso(String(props.pedidoVenda.valorvenda.toFixed(2)).replace('.', ','), { mode: 'currency' }).toUpperCase()})`}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Frete: {getFrete(props.pedidoVenda.frete)}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Condição de Pagamento: {getPagamentoById(props.pedidoVenda.idtipopagamento)}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.tableParcelas}>
                                                {/* TableHeader */}
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Parcela</Text>
                                                    </View>
                                                    <View style={styles.tableCol40}>
                                                        <Text style={styles.tableCell}>Vencimento</Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>Valor</Text>
                                                    </View>
                                                </View>
                                                {/* TableContent */}
                                            </View>
                                            <View style={styles.tableWithoutBorder}>
                                                {props.pedidoVenda.titulos.map((item: any) => (
                                                    <View style={styles.tableRow} key={String(item.idpedidovendatitulo)}>
                                                        <View style={styles.tableColWithoutBorder}>
                                                            <Text style={styles.tableCell}>{item.parcela}</Text>
                                                        </View>
                                                        <View style={styles.tableColWithoutBorder40}>
                                                            <Text style={styles.tableCell}>{new Date(item.datavencimento).toLocaleDateString()}</Text>
                                                        </View>
                                                        <View style={styles.tableColWithoutBorder}>
                                                            <Text style={styles.tableCell}>{getFormatDefault(item.valor)}</Text>
                                                        </View>
                                                    </View>
                                                ))}
                                            </View>
                                            <View style={styles.container}>
                                                <View style={styles.sectionMessage}>
                                                    <Text style={styles.header}>Observações:</Text>
                                                    <Text style={styles.header}> </Text>
                                                    <Text style={styles.header}>{props.pedidoVenda.obs}</Text>
                                                </View>
                                            </View>
                                            <View style={styles.spacing} />
                                            <View style={styles.containerBorder}>
                                                <View style={styles.sectionBorder}>
                                                    <Text style={styles.header}>KG Representação de Cereais LTDA.</Text>
                                                </View>
                                            </View>
                                            <View fixed style={styles.tableColFooter}>
                                                <Text fixed style={styles.tableCellFooter}>KG Representação de Cereais LTDA.</Text>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>
                            ) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
};

export default PedidoVendaReport;