import React from 'react';
import { Backdrop } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Loader from '../../images/puff.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 2,
            color: '#fff',
        },
    }),
);

interface IKLoader {
    isLoading: boolean
    setIsLoading?: Function
}

const KLoader = (props: IKLoader) => {
    const classes = useStyles();

    return (
        <Backdrop
            className={classes.backdrop}
            open={props.isLoading}
        >
            <img src={Loader} alt="Loader" />
        </Backdrop>
    )
}

export default KLoader;