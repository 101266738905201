import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../../components/KAppBar';
import KTable from '../../../components/KTable';
import transportadoraRepositorio from '../../../repositorios/transportadora/transportadoraRepositorio';
import KLoader from '../../../components/KLoader';
import KSnackbar from '../../../components/KSnackbar';
import KDialogCadastro from '../../../components/KDialogCadastro';
import KSearchField from '../../../components/KSearchfield/index';
import cidadeRepositorio from '../../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../../repositorios/representada';
import tipoPagamentoRepositorio from '../../../repositorios/tipoPagamento';
import marcaRepositorio from '../../../repositorios/marca';
import unidadeRepositorio from '../../../repositorios/unidade';
import produtoGrupoRepositorio from '../../../repositorios/produtoGrupo';
import produtoRepositorio from '../../../repositorios/produto';
import usuarioRepositorio from '../../../repositorios/usuario/usuarioRepositorio';
import { ISnack } from '../../../Types';


const ListaUsuario = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [usuario, setUsuario] = useState<any>({})
    const [confirmacaosenha, setConfirmacaoSenha] = useState<any>('');
    const [senhaAtual, setSenhaAtual] = useState<any>('');
    const [privilegio, setPrivilegio] = useState<any>({})
    const [usuarios, setUsuarios] = useState<Array<any>>([]);

    const privilegios = [{
        value: 1,
        label: 'Administrador'
    }, {
        value: 2,
        label: 'Gerente'
    }, {
        value: 3,
        label: 'Colaborador'
    }, {
        value: 4,
        label: 'Usuário'
    }]

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setUsuario({
                ...usuario,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setUsuario({
            ...usuario,
            [campo]: valor
        })
    }

    const handleValidacoes = () => {
        if (!usuario.usuario) {
            return {
                type: 'error',
                message: 'Usuário não pode ser vazio'
            }
        }
        if (!usuario.email) {
            return {
                type: 'error',
                message: 'E-mail não pode ser vazio'
            }
        }
        if (!usuario.senha) {
            return {
                type: 'error',
                message: 'Senha não pode ser vazia'
            }
        }
        if (usuario.senha !== confirmacaosenha && usuario.senha !== senhaAtual) {
            return {
                type: 'error',
                message: 'Senhas não conferem'
            }
        }
        if (!privilegio) {
            return {
                type: 'error',
                message: 'Privilegio não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    //TODO fazer campo de máscara e responsividade na tabela e floatbutton

    const loadUsuarios = async () => {
        try {
            setIsLoading(true);
            const listaUsuario = await usuarioRepositorio.getAll();

            setUsuarios(listaUsuario.data);
            setConfirmacaoSenha('');
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadUsuarios();
        })()
    }, [])

    useEffect(() => {
        setSenhaAtual(usuario.senha);
        setPrivilegio(usuario.idusuario ? privilegios.find((item: any) => item.value == usuario.privilegio) : null);
    }, [usuario.idusuario])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/usuarios')}>Usuários</Link>
                </Breadcrumbs>
                <Typography variant="h5">Usuários</Typography>

                <KTable
                    editable
                    title="Usuário"
                    searchBar
                    maxWidth="sm"
                    searchBarItems={["idusuario", "usuario"]}
                    cadastro
                    item={usuario}
                    setItem={setUsuario}
                    iditem="idusuario"
                    route="usuario"
                    loadItems={loadUsuarios}
                    itemsHeader={["Id", "Nome"]}
                    itemsName={["idusuario", "usuario"]}
                    items={usuarios}
                    setItems={setUsuarios}
                    validator={handleValidacoes}
                >

                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox onChange={handleChangedCheckbox} name="ativo" checked={usuario.ativo} color="primary" />
                            <Typography>Ativo</Typography>
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Usuário"
                                name="usuario"
                                value={usuario.usuario || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <KSearchField
                                {...{ itens: privilegios, value: privilegio, setValue: setPrivilegio, label: 'Privilégio' }}
                                name="privilegio"
                                onChange={handleChangeAuto}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="E-mail"
                                name="email"
                                value={usuario.email || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                margin="dense"
                                label="Senha"
                                name="senha"
                                value={usuario.senha || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 6 : 12}>
                            <TextField
                                fullWidth
                                type="password"
                                variant="outlined"
                                margin="dense"
                                label="Confirmação de senha"
                                value={confirmacaosenha || ''}
                                onChange={(e) => setConfirmacaoSenha(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaUsuario;