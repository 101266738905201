import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import transportadoraRepositorio from '../../repositorios/transportadora/transportadoraRepositorio';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import KDialogCadastro from '../../components/KDialogCadastro';
import KSearchField from '../../components/KSearchfield/index';
import cidadeRepositorio from '../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../repositorios/representada';
import tipoPagamentoRepositorio from '../../repositorios/tipoPagamento';
import produtoGrupoRepositorio from '../../repositorios/produtoGrupo';
import unidadeRepositorio from '../../repositorios/unidade';
import { ISnack } from '../../Types';


const ListaUnidades = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [unidade, setUnidade] = useState<any>({})
    const [unidades, setUnidades] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnidade({
            ...unidade,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setUnidade({
                ...unidade,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUnidade({
            ...unidade,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setUnidade({
            ...unidade,
            [campo]: valor
        })
    }

    const handleValidacoes = () => {
        if (!unidade.descricao) {
            return {
                type: 'error',
                message: 'Descrição não pode ser vazio'
            }
        }
        if (!unidade.fator || isNaN(unidade.fator)) {
            return {
                type: 'error',
                message: 'Fator deve ser apenas numeros e não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    const loadUnidades = async () => {
        try {
            setIsLoading(true);
            const listaUnidades = await unidadeRepositorio.getAll();
            setUnidades(listaUnidades.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadUnidades();
        })()
    }, [])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/unidades')}>Unidades</Link>
                </Breadcrumbs>
                <Typography variant="h5">Unidades</Typography>

                <KTable
                    editable
                    title="Unidade"
                    searchBar
                    maxWidth="sm"
                    searchBarItems={["descricao"]}
                    cadastro
                    item={unidade}
                    setItem={setUnidade}
                    iditem="idunidade"
                    route="unidade"
                    loadItems={loadUnidades}
                    itemsHeader={["Id", "Descrição"]}
                    itemsName={["idunidade", "descricao"]}
                    items={unidades}
                    setItems={setUnidades}
                    validator={handleValidacoes}
                >

                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={!responsive ? 10 : 12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Descrição"
                                name="descricao"
                                value={unidade.descricao || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                        <Grid item xs={!responsive ? 2 : 12}>
                            <TextField
                                fullWidth
                                type="number"
                                variant="outlined"
                                margin="dense"
                                label="Fator"
                                name="fator"
                                value={unidade.fator || ''}
                                onChange={handleChangedNumber}
                            />
                        </Grid>
                    </Grid>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaUnidades;