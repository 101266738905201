import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface ICidadeReposirio {
    getSearchField: Function
}


const cidadeRepositorio: ICidadeReposirio = {
    getSearchField: async () => {
        const response = await api.get('/cidade', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        const listaCidades = response.data.map((item: any) => {
            return {
                label: item.nome,
                value: item.idcidade
            }
        })
        return listaCidades;
    }
}

export default cidadeRepositorio;