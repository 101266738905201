import React, { PureComponent } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

interface IKChart {
    data: Array<any>
}

const KChart = (props: IKChart) => {
    return (
        <div style={{ width: '100%', height: 200 }}>
            {props.data.length > 0 ? (
                <ResponsiveContainer>
                    <LineChart
                        width={500}
                        height={300}
                        data={props.data}
                        margin={{
                            top: 5, right: 30, left: 20, bottom: 5,
                        }}
                    >
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, 500]} allowDataOverflow   />
                        <Tooltip />
                        <Line type="monotone" dataKey="peso" stroke="#8884d8" activeDot={{ r: 8 }}  />
                    </LineChart>
                </ResponsiveContainer>
            ) : null}
        </div>
    );
}

export default KChart;