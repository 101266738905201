import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme, List, ListItemText, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import tipoPagamentoRepositorio from '../../repositorios/tipoPagamento';
import tipoPagamentoParcelaRepositorio from '../../repositorios/tipoPagamentoParcela';
import { ISnack } from '../../Types';


const ListaTipoPagamento = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [tipoPagamento, setTipoPagamento] = useState<any>({})
    const [tiposPagamento, setTiposPagamento] = useState<Array<any>>([]);
    const [] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipoPagamento({
            ...tipoPagamento,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setTipoPagamento({
                ...tipoPagamento,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipoPagamento({
            ...tipoPagamento,
            [event.target.name]: event.target.checked
        })
    }


    const handleValidacoes = () => {
        if (!tipoPagamento.descricao) {
            return {
                type: 'error',
                message: 'Descrição não pode ser vazio'
            }
        }
        if (!tipoPagamento.nparcelas || isNaN(tipoPagamento.nparcelas)) {
            return {
                type: 'error',
                message: 'Nº de Parcelas deve ser apenas numeros e não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    const loadTiposPagamento = async () => {
        try {
            setIsLoading(true);
            const listaTipoPagamento = await tipoPagamentoRepositorio.getAll();
            setTiposPagamento(listaTipoPagamento.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    const handleChangedParcela = (event: any, index: any) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            let listaParcelas = [...tipoPagamento.parcelas];
            listaParcelas[index] = {
                ...listaParcelas[index],
                dia: Number(event.target.value)
            };
            setTipoPagamento({
                ...tipoPagamento,
                parcelas: listaParcelas
            })
        } catch {
            return
        }
    }

    useEffect(() => {
        (async () => {
            await loadTiposPagamento();
        })()
    }, [])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/tipospagamento')}>Condições de pagamento</Link>
                </Breadcrumbs>
                <Typography variant="h5">Condições de pagamento</Typography>
                <KTable
                    editable
                    title="Condição de pagamento"
                    searchBar
                    maxWidth="sm"
                    searchBarItems={["descricao"]}
                    cadastro
                    item={tipoPagamento}
                    setItem={setTipoPagamento}
                    iditem="idtipopagamento"
                    route="tipopagamento"
                    loadItems={loadTiposPagamento}
                    itemsHeader={["Id", "Descrição"]}
                    itemsName={["idtipopagamento", "descricao"]}
                    items={tiposPagamento}
                    setItems={setTiposPagamento}
                    validator={handleValidacoes}
                >
                    <React.Fragment>
                        <KSnackbar
                            snackbar={snackbar}
                            setSnackbar={setSnackbar}
                        />
                        <Grid container spacing={2} item xs={12}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox onChange={handleChangedCheckbox} name="ativo" checked={tipoPagamento.ativo} color="primary" />
                                <Typography>Ativo</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    label="Descrição"
                                    name="descricao"
                                    value={tipoPagamento.descricao || ''}
                                    onChange={handleChanged}
                                />
                            </Grid>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <TextField
                                    fullWidth
                                    disabled={!!tipoPagamento.idtipopagamento}
                                    variant="outlined"
                                    type="number"
                                    margin="dense"
                                    label="Nº Parcelas"
                                    name="nparcelas"
                                    value={tipoPagamento.nparcelas || ''}
                                    onChange={handleChangedNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Parcelas
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <List title="Parcelas">
                                    {tipoPagamento.idtipopagamento ? tipoPagamento.parcelas.map((item: any, index: any) => (
                                        <div>
                                            <ListItem alignItems="flex-start">
                                                <ListItemText
                                                    primary={`${String(item.nparcela)}ª Parcela`}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                style={{ display: 'inline' }}
                                                                color="textPrimary"
                                                            >
                                                                <React.Fragment>
                                                                    <React.Fragment>
                                                                        <div style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <TextField
                                                                                style={{ width: 85 }}
                                                                                type="number"
                                                                                margin="dense"
                                                                                variant="outlined"
                                                                                // onBlur={() => onBlurParcela(index)}
                                                                                onChange={e => handleChangedParcela(e, index)}
                                                                                value={tipoPagamento.parcelas[index].dia} />
                                                                            {' Dia(s)'}
                                                                        </div>
                                                                    </React.Fragment>
                                                                </React.Fragment>
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </div>
                                    )) : null}
                                </List>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaTipoPagamento;