import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Paper, Table, useTheme, Theme, AppBar, Toolbar, Typography, Button, IconButton, TableContainer, TableBody, TableHead, TableRow, TableCell, useMediaQuery, Grid, Tooltip } from '@material-ui/core';
import { Close, Add, DeleteOutlined, ArrowForwardOutlined, PrintOutlined, EditOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import currencyFormatter from 'currency-formatter';

import CadastroOrdemCarregamento from './Cadastro';
import OrdemCarregamentoReport from './Report';
import pedidoVendaCarregamentoRepositorio from '../../../repositorios/pedidoVendaCarregamento';
import KSnackbar from '../../../components/KSnackbar';
import { ISnack } from '../../../Types';

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        minHeight: 300,
        maxHeight: 300,
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto',
            maxHeight: 'none'
        }
    },
    grid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    button: {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

interface IOrdemCarregamento {
    open: boolean
    setOpen: Function
    children?: ReactNode
    pedidoVenda: any
    setPedidoVenda: Function
}

const OrdemCarregamento = (props: IOrdemCarregamento) => {
    const theme = useTheme();
    const classes = useStyles();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [openModalCadastro, setOpenModalCadastro] = useState<boolean>(false);
    const [openRelatorio, setOpenRelatorio] = useState<boolean>(false);
    const [openModalRelatorio, setOpenModalRelatorio] = useState<boolean>(false);
    const [ordem, setOrdem] = useState<any>({});
    const [index, setIndex] = useState<number>(0);
    const [adicionar, setAdicionar] = useState<boolean>(true);
    const [totalPedido, setTotalPedido] = useState<number>(0);
    const [totalCarregado, setTotalCarregado] = useState<number>(0);
    const [ordensCarregamento, setOrdensCarregamento] = useState<Array<any>>([]);
    const [ordemCarregamento, setOrdemCarregamento] = useState<any>({});
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const getFormatDefault = (value: number) => {
        return currencyFormatter.format(value, {
            symbol: '',
            decimal: ',',
            thousand: '.',
            precision: 3,
            format: '%v' // %s is the symbol and %v is the value
        });
    }

    const getTotalPedido = () => {
        let resultado = ordensCarregamento.reduce((acum, atual) => {
            let resultadoAtual = atual.peso ? atual.peso + acum : acum + 0;
            return resultadoAtual;
        }, 0)
        return getFormatDefault(resultado);
    }

    const onClickNovo = () => {
        setOpenModalCadastro(true);
    }
    const onClickEditar = (item: any) => {
        setOrdemCarregamento(item);
        setOpenModalCadastro(true);
    }
    const onClickRelatorio = (item: any, index: number) => {
        setOrdem(item);
        setIndex(index + 1);
        setOpenModalRelatorio(true);
    }

    const onClose = () => {
        // props.setPedidoVenda({
        //     titulos: [],
        //     ordensCarregamento: [],
        //     comissao: 0
        // })
        props.setOpen(false);
    }

    const onClickDelete = async (ordem: any) => {
        try {
            const response = await pedidoVendaCarregamentoRepositorio.delete(ordem.idpedidovendacarregamento);
            if (response.status !== 204) {
                return;
            }
            setSnackbar({
                visible: true,
                variant: 'success',
                message: 'Ordem removida com sucesso!'
            });
            await loadOrdens();
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possível remover'
            });
        }
    }

    const loadOrdens = async () => {
        try {
            const response = await pedidoVendaCarregamentoRepositorio.getAllPorPedido(props.pedidoVenda.idpedidovenda);
            setOrdensCarregamento(response.data);
            let resultado = response.data.reduce((acum: any, atual: any) => {
                let resultadoAtual = atual.peso ? atual.peso + acum : acum + 0;
                return resultadoAtual;
            }, 0);

            if (props.pedidoVenda.peso <= resultado) {
                setAdicionar(false);
            } else {
                setAdicionar(true);
            }
            let carregado = response.data.reduce((acum: any, atual: any) => {
                let resultadoAtual = atual.peso ? atual.peso + acum : acum + 0;
                return resultadoAtual;
            }, 0)
            setTotalCarregado(carregado)

            let total = props.pedidoVenda.peso;
            setTotalPedido(total);

        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possível carregar!'
            });
        }
    }

    useEffect(() => {
        if (props.open) {
            (loadOrdens)()
        }
    }, [props.open])

    return (
        <React.Fragment>
            <KSnackbar
                snackbar={snackbar}
                setSnackbar={setSnackbar}
            />
            <Dialog
                open={props.open}
                onClose={onClose}
                maxWidth="md"
                fullWidth
                fullScreen={responsive}
            >
                <OrdemCarregamentoReport
                    open={openModalRelatorio}
                    setOpen={setOpenModalRelatorio}
                    index={index}
                    ordem={ordem}
                    pedidoVenda={props.pedidoVenda}
                />
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography style={{ color: theme.palette.secondary.main }}>Ordens de carregamento</Typography>
                        <IconButton onClick={onClose}>
                            <Close style={{ color: theme.palette.secondary.main }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent className={classes.content}>
                    <CadastroOrdemCarregamento
                        totalPedido={totalPedido}
                        totalCarregado={totalCarregado}
                        pedidoVenda={props.pedidoVenda}
                        loadOrdens={loadOrdens}
                        open={openModalCadastro}
                        setOpen={setOpenModalCadastro}
                        ordemCarregamento={ordemCarregamento}
                        setOrdemCarregamento={setOrdemCarregamento}
                    />
                    <Toolbar className={classes.toolbar}>
                        <Button
                            startIcon={<Add style={{ color: theme.palette.secondary.main }} />}
                            className={classes.button}
                            onClick={onClickNovo}
                            disabled={!adicionar}
                        >
                            Adicionar
                        </Button>
                    </Toolbar>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    {!responsive ? (
                                        <TableCell align="center"></TableCell>
                                    ) : null}
                                    <TableCell align="center">Pedido</TableCell>
                                    <TableCell align="center">Ordem</TableCell>
                                    <TableCell align="center">Placa</TableCell>
                                    <TableCell align="center">Motorista</TableCell>
                                    <TableCell align="center">Aos cuidados de</TableCell>
                                    <TableCell align="center">Peso</TableCell>
                                    <TableCell align="center">Observação</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ordensCarregamento.map((item: any, index: number) => (
                                    <TableRow>
                                        <TableCell align="center">
                                            <Tooltip title="Editar ordem de carregamento">
                                                <IconButton onClick={() => onClickEditar(item)}>
                                                    <EditOutlined style={{
                                                        color: theme.palette.primary.main
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        {!responsive ? (
                                            <TableCell align="center">
                                                <Tooltip title="Imprimir ordem de carregamento">
                                                    <IconButton onClick={() => onClickRelatorio(item, index)}>
                                                        <PrintOutlined style={{
                                                            color: theme.palette.primary.main
                                                        }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        ) : null}
                                        <TableCell align="center">{item.idpedidovendarepresentada}</TableCell>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">{item.placa}</TableCell>
                                        <TableCell align="center">{item.motorista}</TableCell>
                                        <TableCell align="center">{item.aoscuidadosde}</TableCell>
                                        <TableCell align="center">{getFormatDefault(item.peso)}</TableCell>
                                        <TableCell align="center">{item.obs}</TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => onClickDelete(item)}
                                            >
                                                <DeleteOutlined style={{ color: theme.palette.primary.main }} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    {responsive ? (
                        <Grid container item xs={12}>
                            <Grid item xs={6} className={classes.grid}>
                                <Typography>Total do Pedido</Typography>
                                <Typography>{props.pedidoVenda.peso ? getFormatDefault(props.pedidoVenda.peso) : getFormatDefault(0)}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.grid}>
                                <Typography>Total Carregado</Typography>
                                <Typography>{getTotalPedido()}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                            <React.Fragment>
                                <Typography>Total do Pedido</Typography>
                                <ArrowForwardOutlined />
                                <Typography>{props.pedidoVenda.peso ? getFormatDefault(props.pedidoVenda.peso) : getFormatDefault(0)}</Typography>
                                <Typography>  </Typography>
                                <Typography>Total Carregado</Typography>
                                <ArrowForwardOutlined />
                                <Typography>{getTotalPedido()}</Typography>
                                <Typography>  </Typography>
                            </React.Fragment>
                        )}
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}

export default OrdemCarregamento;