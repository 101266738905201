import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IUsuarioRepositorio {
    auth: Function
    getAll: Function
    getSearchField: Function
}

interface IDtoLogar {
    usuario: string,
    senha: string
}

const usuarioRepositorio: IUsuarioRepositorio = {
    auth: async (dtoLogin: IDtoLogar) => {
        const response = await api.post('/auth/authenticate', dtoLogin);
        return response;
    },
    getAll: async () => {
        const response = await api.get('/usuario', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/usuario', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        const listaUsuario = response.data.map((item: any) => {
            return {
                label: item.usuario,
                value: item.idusuario
            }
        })
        return listaUsuario;
    }
}

export default usuarioRepositorio;