import React, { useState } from 'react';
import { useStyles } from './styles';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, withStyles } from '@material-ui/core';



export const KSelect = (props: any) => {
    const classes = useStyles();

    const handleChange = (event: any) => {
        if (props.setValueArray) {
            let array = [...props.valueArray];
            array[props.index] = event.target.value;
            props.setValueArray(array);
        }

        if (props.setValue) {
            props.setValue(event.target.value);
        }
        if (props.onChange) {
            props.onChange(event);
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {props.custom ? (
                <TextField
                    fullWidth
                    variant="outlined"
                    disabled={props.disabled}
                    margin="dense"
                    label={props.label}
                    name={props.name}
                    className={props.className}
                    value={props.value}
                    onChange={e => handleChange(e)}
                    select
                >
                    {props.itens.map((item: any) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                </TextField>
            ) : (
                    <TextField
                        fullWidth
                        variant="outlined"
                        disabled={props.disabled}
                        margin="dense"
                        label={props.label}
                        name={props.name}
                        className={props.className}
                        value={props.value}
                        onChange={e => handleChange(e)}
                        select
                    >
                        {props.itens.map((item: any) => (
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                    </TextField>
                )}
        </div >
    )
};

export default KSelect