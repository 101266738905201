import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useStyles } from './styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Typography, Tooltip } from '@material-ui/core';
import { VariableSizeList } from 'react-window';
import { useTheme } from '@material-ui/core/styles';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    //height="100%"
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.key}
    </ListSubheader>,
    params.children,
];

const KSearchField = (props) => {
    const classes = useStyles();
    const cName = { className: "MuiAutocomplete-inputRootDense" };

    return (
        <form autoComplete="off">
            <Autocomplete
                disableListWrap
                options={props.itens}
                name={props.name}
                clearText='Limpar '
                className={classes.root}
                getOptionLabel={option => option.label}
                style={{ width: '100%' }}
                noOptionsText="Não há nada para selecionar"
                value={props.value || ''}
                renderGroup={renderGroup}
                ListboxComponent={ListboxComponent}
                onChange={(event, newValue) => {
                    if (props.setValue) {
                        props.setValue(newValue);
                    }
                    if (props.onChange && newValue) {
                        props.onChange(props.name, newValue.value)
                    }
                    if (props.setValueArray) {
                        let array = [...props.valueArray];
                        array[props.index][props.tipoArray] = newValue;
                        props.setValueArray(array);
                    }
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        className={classes.textfield}
                        required={props.required}
                        disabled={props.disabled}
                        label={props.label}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            ...cName,
                            readOnly: props.readOnly,
                            ...[props.InputProps ? props.InputProps : {}]
                        }}
                    />
                )}
                renderOption={(option) => (
                    <Tooltip title={option.label}>
                        <Typography noWrap>{option.label}</Typography>
                    </Tooltip>
                )}
            />
        </form>
    )
};


export default KSearchField;

/*
Exemplo de uso:

**Variavel para receber o valor selecionado:

const [value, setValue] = useState((props.item.value ? {
    label: props.item.value.nome, value: props.item.idvalue
} : {
        label: '', value: 0
    }) || null);


**Variavel para receber o array de cidades para enviar para o componente:

const [itens, setItens] = useState([]);



**Forma de preencher o array para o componente:
useEffect(() => {
    let listaValues = [];

    api.get('/value', {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token')
        }
    }).then(response => {
        response.data.map(item => {
            listaValues.push({
                label: item.nome,
                value: item.idvalue
            })
        })
        setValue(listaValues);
    })
},[])


**forma de utilizar no return de componente

<USearchField {...{ itens: itens, value: value, setValue: setvalue, label: 'Titulo' }} />
*/