import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IPedidoVendaCarregamentoRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getAllPorPedido: Function
    delete: Function
}

const pedidoVendaCarregamentoRepositorio: IPedidoVendaCarregamentoRepositorio = {
    getAll: async () => {
        const response = await api.get('/pedidovendacarregamento', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idpedidovendacarregamento: number) => {
        const response = await api.get('/pedidovendacarregamento/' + idpedidovendacarregamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    delete: async (idpedidovendacarregamento: number) => {
        const response = await api.delete('/pedidovendacarregamento/' + idpedidovendacarregamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtopedidovendacarregamento: any) => {
        const response = await api.post('/pedidovendacarregamento', dtopedidovendacarregamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtopedidovendacarregamento: any) => {
        const response = await api.put('/pedidovendacarregamento/' + dtopedidovendacarregamento.idpedidovendacarregamento, dtopedidovendacarregamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getAllPorPedido: async (idpedidovenda: any) => {
        const response = await api.get('/pedidovendacarregamento/GetAllPorPedido', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token'),
                idpedidovenda: idpedidovenda
            }
        });
        return response;
    }
}

export default pedidoVendaCarregamentoRepositorio;