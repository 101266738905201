import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IPedidoVendaRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getFiltrado: Function
    getTotalDashboard: Function
    getPedidosSemanais: Function
    getUltimasVendas: Function
    cancelarPedido: Function
}

const pedidoVendaRepositorio: IPedidoVendaRepositorio = {
    getAll: async () => {
        const response = await api.get('/pedidovenda', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idpedidovenda: number) => {
        const response = await api.get('/pedidovenda/' + idpedidovenda, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtopedidovenda: any) => {

        dtopedidovenda.sessionId = cookies.get('sessionId');

        const response = await api.post('/pedidovenda', dtopedidovenda, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtopedidovenda: any) => {
        const response = await api.put('/pedidovenda/' + dtopedidovenda.idpedidovenda, dtopedidovenda, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getFiltrado: async (dtoPesquisaPedidoVenda: any) => {
        const response = await api.post('/pedidovenda/GetFiltrado', dtoPesquisaPedidoVenda, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getTotalDashboard: async () => {
        const response = await api.get('/pedidovenda/GetTotalDashboard', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getPedidosSemanais: async () => {
        const response = await api.get('/pedidovenda/GetPedidosSemanais', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getUltimasVendas: async () => {
        const response = await api.get('/pedidovenda/GetUltimasVendas', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    cancelarPedido: async (idpedidovenda: number) => {
        const response = await api.put('/pedidovenda/CancelarPedido/' + idpedidovenda, {}, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    }
}

export default pedidoVendaRepositorio;