import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useStyles } from './styles';


const KFloatButton = (props: any) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [hidden, setHidden] = React.useState(false);

    const handleVisibility = () => {
        setHidden(prevHidden => !prevHidden);
    };

    const handleClick = () => {
        if (props.actions) {
            if (props.actions.length <= 0) {
                if (props.handleClick) {
                    props.handleClick()
                    return;
                }
            }
            if (props.setOpen) {
                props.setOpen(true);
            }
        }
    }

    const handleOpen = (action: any) => {
        props.setOpen(false);
        if (action.action) {
            action.action();
        }

    };

    const handleClose = () => {
        if (props.setOpen) {
            props.setOpen(false);
        }
    };

    return (
        <div className={classes.root}>
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                className={props.className ? props.className : classes.speedDial}
                hidden={hidden}
                onClick={handleClick}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={props.setOpen}
                open={props.open}
            >
                {props.actions.map((action: any) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={() => handleOpen(action)}
                    />
                ))}
            </SpeedDial>
        </div>
    )
};

export default KFloatButton