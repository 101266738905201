import React from 'react';
import { BrowserRouter, Route, Redirect, useHistory, Switch } from 'react-router-dom';
import Cookies from 'universal-cookie';

import Login from './pages/Login';
import Dashboard from './pages/dashboard';
import ListaUsuario from './pages/Usuario/Lista';
import ListaTransportadora from './pages/Transportadora/Lista';
import ListaRepresentada from './pages/Representante';
import ListaTipoPagamento from './pages/TiposPagamento';
import ListaProdutoGrupo from './pages/ProdutoGrupo';
import ListaUnidades from './pages/Unidade';
import ListaMarca from './pages/Marca';
import ListaProduto from './pages/Produto';
import ListaCliente from './pages/Cliente';
import ListaPedidoVenda from './pages/PedidoVenda';


const cookies = new Cookies();

const PrivateRoute = ({ component: Component, ...rest }: any) => {
    return (
        <Route {...rest} render={props => (
            cookies.get('token') ? (
                <Component {...{ ...props }} />
            ) : (
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                )

        )} />

    )
}

const Routes = () => {
    const history = useHistory();
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" render={() => (
                    cookies.get('token') ? (
                        <Redirect to={{ pathname: '/dashboard' }} />
                    ) : (
                            <Login />
                        )
                )} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/usuarios" component={ListaUsuario} />
                <PrivateRoute exact path="/transportadoras" component={ListaTransportadora} />
                <PrivateRoute exact path="/representadas" component={ListaRepresentada} />
                <PrivateRoute exact path="/tipospagamento" component={ListaTipoPagamento} />
                <PrivateRoute exact path="/gruposproduto" component={ListaProdutoGrupo} />
                <PrivateRoute exact path="/unidades" component={ListaUnidades} />
                <PrivateRoute exact path="/marcas" component={ListaMarca} />
                <PrivateRoute exact path="/produtos" component={ListaProduto} />
                <PrivateRoute exact path="/clientes" component={ListaCliente} />
                <PrivateRoute exact path="/pedidosvenda" component={ListaPedidoVenda} />
                <PrivateRoute exact path="*" component={() => (
                    <Redirect to="/dashboard" />
                )} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;