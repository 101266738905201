import React, { useState, useEffect } from 'react';
import { Breadcrumbs, Link, Typography, Grid, TextField, Checkbox, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import KAppBar from '../../components/KAppBar';
import KTable from '../../components/KTable';
import transportadoraRepositorio from '../../repositorios/transportadora/transportadoraRepositorio';
import KLoader from '../../components/KLoader';
import KSnackbar from '../../components/KSnackbar';
import KDialogCadastro from '../../components/KDialogCadastro';
import KSearchField from '../../components/KSearchfield/index';
import cidadeRepositorio from '../../repositorios/cidade/cidadeRepositorio';
import estadoRepositorio from '../../repositorios/estado/estadoRepositorio';
import representadaRepositorio from '../../repositorios/representada';
import tipoPagamentoRepositorio from '../../repositorios/tipoPagamento';
import produtoGrupoRepositorio from '../../repositorios/produtoGrupo';
import { ISnack } from '../../Types';


const ListaProdutoGrupo = () => {
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [produtoGrupo, setProdutoGrupo] = useState<any>({})
    const [produtoGrupos, setProdutoGrupos] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleLinkClick = (path: string) => {
        history.push(path);
    }


    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProdutoGrupo({
            ...produtoGrupo,
            [event.target.name]: event.target.value
        })
    }

    const handleChangedNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value) < 0) {
                    return
                }
            }
            setProdutoGrupo({
                ...produtoGrupo,
                [event.target.name]: event.target.value ? Number(event.target.value) : null
            })
        } catch {
            return
        }

    }
    const handleChangedCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProdutoGrupo({
            ...produtoGrupo,
            [event.target.name]: event.target.checked
        })
    }

    const handleChangeAuto = (campo: any, valor: any) => {
        setProdutoGrupo({
            ...produtoGrupo,
            [campo]: valor
        })
    }

    const handleValidacoes = () => {
        if (!produtoGrupo.descricao) {
            return {
                type: 'error',
                message: 'Descrição não pode ser vazio'
            }
        }
        return {
            type: 'success',
            message: ''
        }
    }

    //TODO fazer campo de máscara e responsividade na tabela e floatbutton

    const loadProdutoGrupos = async () => {
        try {
            setIsLoading(true);
            const listaProdutoGrupos = await produtoGrupoRepositorio.getAll();
            setProdutoGrupos(listaProdutoGrupos.data);
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Não foi possivel Carregar'
            })
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await loadProdutoGrupos();
        })()
    }, [])

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
                <Breadcrumbs separator=">">
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/dashboard')}>Dashboard</Link>
                    <Link style={{ cursor: 'pointer' }} onClick={() => handleLinkClick('/gruposproduto')}>Grupos de Produto</Link>
                </Breadcrumbs>
                <Typography variant="h5">Grupos de Produto</Typography>

                <KTable
                    editable
                    title="Grupo de Produto"
                    searchBar
                    maxWidth="sm"
                    searchBarItems={["idprodutogrupo", "descricao"]}
                    cadastro
                    item={produtoGrupo}
                    setItem={setProdutoGrupo}
                    iditem="idprodutogrupo"
                    route="produtogrupo"
                    loadItems={loadProdutoGrupos}
                    itemsHeader={["Id", "Descrição"]}
                    itemsName={["idprodutogrupo", "descricao"]}
                    items={produtoGrupos}
                    setItems={setProdutoGrupos}
                    validator={handleValidacoes}
                >

                    <Grid container spacing={2} item xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                label="Descrição"
                                name="descricao"
                                value={produtoGrupo.descricao || ''}
                                onChange={handleChanged}
                            />
                        </Grid>
                    </Grid>
                </KTable>
            </div>
        </KAppBar>
    )
}

export default ListaProdutoGrupo;