import React, { useEffect, useState } from "react";
import currencyFormatter from "currency-formatter";
import {
  Grid,
  Grow,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import KAppBar from "../../components/KAppBar";
import KChart from "../../components/KChart";
import usuarioRepositorio from "../../repositorios/usuario/usuarioRepositorio";
import pedidoVendaRepositorio from "../../repositorios/pedidoVenda";
import KLoader from "../../components/KLoader";

const Dashboard = () => {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalPendente, setTotalPendente] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pedidosSemanais, setPedidosSemanais] = useState<Array<any>>([]);
  const [data, setData] = useState<Array<any>>([]);

  const situacoes = [
    {
      label: "Pendente",
      value: 1,
      color: "#ef6c00",
    },
    {
      label: "Carregado",
      value: 2,
      color: "#2e7d32",
    },
    {
      label: "Cancelado",
      value: 3,
      color: "#c62828",
    },
    {
      label: "Todos",
      value: 4,
      color: "#c62828",
    },
  ];

  const getSituacao = (situacao: any) => {
    return (
      situacoes.find((item: any) => item.value == situacao) || {
        label: "",
        value: 0,
        color: "#c62828",
      }
    );
  };

  const getFormatDefault = (value: number) => {
    return currencyFormatter.format(value, {
      symbol: "",
      decimal: ",",
      thousand: ".",
      precision: 0,
      format: "%v", // %s is the symbol and %v is the value
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const responseTotal = await pedidoVendaRepositorio.getTotalDashboard();
        const responseSemanal =
          await pedidoVendaRepositorio.getPedidosSemanais();
        const dataResponse = await pedidoVendaRepositorio.getUltimasVendas();
        const dataFormatada = dataResponse.data.map((item: any) => {
          return {
            name: item.name,
            peso: getFormatDefault(item.peso || 0),
          };
        });

        setData(dataFormatada);
        setPedidosSemanais(responseSemanal.data);
        setTotalPendente(responseTotal.data.total);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <React.Fragment>
      <KLoader isLoading={isLoading} />
      <KAppBar>
        <Grid
          container
          spacing={3}
          style={{
            margin: 0,
          }}
          item
          xs={12}
        >
          <Grid item xs={responsive ? 12 : 8}>
            <Grow in={!isLoading} timeout={500}>
              <Paper elevation={3} style={{ height: 250, padding: 15 }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Relação Semanal
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <KChart data={data} />
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          </Grid>
          <Grid item xs={responsive ? 12 : 4}>
            <Grow in={!isLoading} timeout={1000}>
              <Paper elevation={3} style={{ height: 250, padding: 15 }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Peso Total Pendente
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 165,
                    }}
                  >
                    <Typography variant="h4" style={{ color: "#646464" }}>
                      {getFormatDefault(totalPendente)}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grow>
          </Grid>
          <Grid item xs={12}>
            <Grow in={!isLoading} timeout={1500}>
              <Paper elevation={3} style={{ height: 300, padding: 15 }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Pedidos Semanais
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer style={{ maxHeight: 250 }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Emissão</TableCell>
                          <TableCell align="center">Situação</TableCell>
                          <TableCell align="center">Cliente</TableCell>
                          <TableCell align="center">Peso Total</TableCell>
                          <TableCell align="center">Representada</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pedidosSemanais.map((item: any) => (
                          <TableRow key={String(item.idpedidovenda)}>
                            <TableCell align="center">
                              {new Date(item.dataemissao).toLocaleDateString()}
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                style={{
                                  color: getSituacao(item.situacao).color,
                                  border: `solid 1px ${
                                    getSituacao(item.situacao).color
                                  }`,
                                  borderRadius: 25,
                                }}
                              >
                                {getSituacao(item.situacao).label}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {item.cliente.nome}
                            </TableCell>
                            <TableCell align="center">
                              {getFormatDefault(item.peso)}
                            </TableCell>
                            <TableCell align="center">
                              {item.representada.nome}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Paper>
            </Grow>
          </Grid>
          <Grow in={!isLoading} timeout={2000}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="body2"
                style={{ color: theme.palette.primary.main }}
              >{`KG Representação de Cereais LTDA. de ${String(
                new Date().getFullYear()
              )}.`}</Typography>
            </Grid>
          </Grow>
        </Grid>
      </KAppBar>
    </React.Fragment>
  );
};

export default Dashboard;
