import React, { useEffect, useState } from 'react';
import { AppBar, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, makeStyles, TextField, Theme, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import KLoader from '../../../../components/KLoader';
import pedidoVendaCarregamentoRepositorio from '../../../../repositorios/pedidoVendaCarregamento';
import KSnackbar from '../../../../components/KSnackbar';
import { ISnack } from '../../../../Types';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.primary.dark,
        }
    }
}))

interface ICadastroOrdemCarregamento {
    open: boolean
    setOpen: Function
    pedidoVenda: any
    loadOrdens: Function
    totalPedido: number
    totalCarregado: number
    ordemCarregamento: any
    setOrdemCarregamento: Function
}

const CadastroOrdemCarregamento = (props: ICadastroOrdemCarregamento) => {
    const theme = useTheme();
    const classes = useStyles();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [ordemCarregamento, setOrdemCarregamento] = useState<any>(props.ordemCarregamento || {});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'success',
        message: 'Dados Salvos'
    });

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOrdemCarregamento({
            ...ordemCarregamento,
            [event.target.name]: event.target.value
        });
    }

    const handleClose = () => {
        props.setOpen(false);
        setOrdemCarregamento({});
        props.setOrdemCarregamento({})
    }

    const validaValorRestante = (valor: number = 0) => {
        let resultado = props.totalCarregado + Number(valor);
        if (ordemCarregamento.idpedidovendacarregamento) {
            resultado -= props.ordemCarregamento.peso;
        }
        return resultado <= props.totalPedido ? true : false;
    }


    const onClickSalvar = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const dto = {
            ...ordemCarregamento,
            idpedidovenda: props.pedidoVenda.idpedidovenda
        }

        try {
            if (!dto.peso || dto.peso <= 0) {
                setSnackbar({
                    visible: true,
                    variant: 'error',
                    message: 'Peso não pode ser vazio e menor ou igual a 0!'
                })
                return;
            }

            if (!validaValorRestante(dto.peso)) {
                setSnackbar({
                    visible: true,
                    variant: 'error',
                    message: 'Peso carregado não pode ser maior que o pedido!'
                })
                return;
            }

            if (ordemCarregamento.idpedidovendacarregamento) {
                const response = await pedidoVendaCarregamentoRepositorio.update(dto);
                if (response.status !== 204) {
                    return;
                }

                await props.loadOrdens();
                props.setOpen(false);
                setOrdemCarregamento({});
                props.setOrdemCarregamento({});
                setSnackbar({
                    visible: true,
                    variant: 'success',
                    message: 'Dados Salvos!'
                })
                return;
            }

            const response = await pedidoVendaCarregamentoRepositorio.add(dto);
            if (response.status !== 201) {
                return;
            }

            await props.loadOrdens();
            props.setOpen(false);
            setOrdemCarregamento({});
            props.setOrdemCarregamento({});
            setSnackbar({
                visible: true,
                variant: 'success',
                message: 'Dados Salvos!'
            })
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Erro ao adicionar!'
            })
        }
    }

    useEffect(() => {
        setOrdemCarregamento(props.ordemCarregamento);
    }, [props.ordemCarregamento, setOrdemCarregamento])

    return (
        <React.Fragment>
            {!props.open ? (
                <KSnackbar
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                />
            ) : null}
            <Dialog
                open={props.open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                fullScreen={responsive}
            >
                <form onSubmit={e => onClickSalvar(e)}>
                    <KSnackbar
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                    />
                    <KLoader isLoading={isLoading} />
                    <AppBar position="relative">
                        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography style={{ color: theme.palette.secondary.main }}>Ordem de carregamento</Typography>
                            <IconButton onClick={handleClose}>
                                <Close style={{ color: theme.palette.secondary.main }} />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container item xs={12} spacing={2} style={{ margin: 0 }}>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Placa"
                                    name="placa"
                                    value={ordemCarregamento.placa}
                                    onChange={handleChanged}
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="motorista"
                                    value={ordemCarregamento.motorista}
                                    onChange={handleChanged}
                                    label="Motorista"
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="aoscuidadosde"
                                    value={ordemCarregamento.aoscuidadosde}
                                    onChange={handleChanged}
                                    label="Aos cuidados de"
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="peso"
                                    type="number"
                                    value={ordemCarregamento.peso}
                                    onChange={handleChanged}
                                    label="Peso"
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="obs"
                                    value={ordemCarregamento.obs}
                                    onChange={handleChanged}
                                    label="Obs"
                                    variant="outlined"
                                    margin="dense"
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" className={classes.button}>Adicionar</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    )
}

export default CadastroOrdemCarregamento;