import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';

export const KMaskTextField = (props: any) => {
    const { inputRef, ...other } = props;

    return (
        <InputMask mask={props.mask} formatChars={props.formatChars} alwaysShowMask={props.alwaysShowMask === false ? props.alwaysShowMask : true} maskChar={props.maskChar} name={props.name} value={props.value} onChange={props.onChange} onFocus={props.onFocus}>
            {(inputProps: any) => <TextField fullWidth {...inputProps} onInput={props.onInput} className={props.className} error={props.error} helperText={props.error ? props.helperText : null} required={props.required} variant="outlined" margin="dense" type="tel" disableUnderline label={props.label} />}
        </InputMask>
    )
};

export default KMaskTextField

/*
Exemplos de Uso:
import UMaskTextfield from '../../components/umasktextfield'
const [value, setValue] = useState('');

no Return:

<UMaskTextfield
    helperText=""   *** qualquer texto para a mensagem de erro
    mask="999.999.999-99"   *** ou qualquer uma da sua necessidade
    error={erro}    *** variavel para verificar se vai mostrar mensagem de erro ou não
    name="teste"
    value={value}
    label='CpfCnpj'
    className={classes.seila}
    onChange={handleChanged}
/>


*/