import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { AppBar, Dialog, DialogActions, DialogContent, Grid, IconButton, Table, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import Logo from '../../../images/Logo.png';
import { Close } from '@material-ui/icons';

// Create styles
const styles = StyleSheet.create<any>({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        fontSize: 12,
        color: 'grey',
    },
    image: {
        width: 111,
        height: 56
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableWithoutBorder: {
        display: "table",
        width: "auto",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "20%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColFooter: {
        width: "100%",
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableColWithoutBorder: {
        marginBottom: 5,
        width: "20%"
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8
    },
    tableCellFooter: {
        marginTop: 5,
        fontSize: 8
    },

});

interface IClientePDF {
    open: boolean
    setOpen: Function
    clientes: any
}

// Create Document Component
const MyDocument = (props: IClientePDF) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="md"
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar style={{
                        background: '#646464',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{ color: '#fff' }}>Relatório de Clientes</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <Close style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        minHeight: 600,
                        maxHeight: 600
                    }}
                >
                    <Grid container item xs={12} style={{ height: '580px' }}>
                        <Grid item xs={12} style={{height: '100%'}}>
                            <PDFViewer >
                                <Document title="Relatório_Clientes_KG">
                                    <Page size="A4" style={styles.page}>
                                        <View style={styles.container} fixed>
                                            <View style={styles.section}>
                                                <Image
                                                    style={styles.image}
                                                    src={Logo}
                                                />
                                            </View>
                                            <View style={styles.section}>
                                                <Text style={styles.header}>Relatório de Clientes</Text>
                                            </View>
                                            <View style={styles.section}>
                                                <Text style={styles.header}>{`Data: ${new Date().toLocaleString().substr(0, 16)}`}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.container} fixed>
                                            <View style={styles.section} >
                                                <Text style={styles.header}>KG REPRESENTAÇÃO DE CEREAIS LTDA.</Text>
                                            </View>
                                            <View style={styles.section} >
                                                <Text style={styles.header} render={({ pageNumber, totalPages }) => (
                                                    `${pageNumber} / ${totalPages}`
                                                )} fixed />
                                            </View>
                                        </View>
                                        <View style={styles.table}>
                                            {/* TableHeader */}
                                            <View style={styles.tableRow}>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Razão Social</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Cidade</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>UF</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Telefone</Text>
                                                </View>
                                                <View style={styles.tableCol}>
                                                    <Text style={styles.tableCell}>Contato</Text>
                                                </View>
                                            </View>
                                            {/* TableContent */}
                                        </View>
                                        <View style={styles.tableWithoutBorder}>
                                            {props.clientes.map((item: any) => (
                                                <View style={styles.tableRow} key={String(item.idcliente)}>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{item.razaosocial}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{item.cidadePrincipal ? item.cidadePrincipal.nome : 'Não Preenchido'}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{item.estadoPrincipal ? item.estadoPrincipal.sigla : 'Não Preenchido'}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{item.foneprincipal}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{item.contatoprincipal}</Text>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        <View fixed style={styles.tableColFooter}>
                                            <Text style={styles.tableCellFooter}>KG Representação de Cereais LTDA.</Text>
                                        </View>
                                    </Page>
                                </Document>
                            </PDFViewer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default MyDocument;