import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface ITipoPagamentoRepositorio {
    getAll: Function
    get: Function
    add: Function
    update: Function
    getSearchField: Function
}

const tipoPagamentoRepositorio: ITipoPagamentoRepositorio = {
    getAll: async () => {
        const response = await api.get('/tipopagamento', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    get: async (idtipopagamento: number) => {
        const response = await api.get('/tipopagamento/' + idtipopagamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    add: async (dtotipopagamento: any) => {
        const response = await api.post('/tipopagamento', dtotipopagamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    update: async (dtotipopagamento: any) => {
        const response = await api.put('/tipopagamento/' + dtotipopagamento.idtipopagamento, dtotipopagamento, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },
    getSearchField: async () => {
        const response = await api.get('/tipopagamento', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        const listaTipoPagamento = response.data.map((item: any) => {
            return {
                label: item.descricao,
                value: item.idtipopagamento,
                parcelas: item.parcelas
            }
        })
        return listaTipoPagamento;
    }
}

export default tipoPagamentoRepositorio;