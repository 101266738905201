import React, { useState } from 'react';
import { Dialog, AppBar, DialogContent, DialogActions, useMediaQuery, Typography, Button, useTheme, Theme, Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { Close, Save } from '@material-ui/icons'
import api from '../../Api';
import Cookie from 'universal-cookie';
import KSnackbar from '../KSnackbar';
import { ISnack } from '../../Types';

interface IKDialogCadastro {
    open: boolean
    setOpen: Function
    maxWidth: false | "md" | "xs" | "sm" | "lg" | "xl" | undefined
    children: React.ReactElement
    title: string
    item: any
    route: string
    iditem: string
    loadItems: Function
    validator: any
    noPadding?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.secondary.main
    },
    button: {
        background: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark
        }
    }
}));

const KDialogCadastro = (props: IKDialogCadastro) => {
    const classes = useStyles();
    const theme = useTheme();
    const cookies = new Cookie();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'warning',
        message: 'Usuário e/ou senha incorreto(s)!'
    });

    const onClickSalvar = async () => {
        try {
            let validation = props.validator ? props.validator() : { type: 'success', message: '' };
            if (validation.type === 'error') {
                setSnackbar({
                    visible: true,
                    variant: 'error',
                    message: validation.message
                })
                return
            }
            let dto = {
                ...props.item
            }
            if (!props.item[props.iditem]) {
                const responseSalvar = await api.post(`/${props.route}`, dto, {
                    headers: {
                        Authorization: 'Bearer ' + cookies.get('token')
                    }
                });
                if (responseSalvar.status === 201) {
                    props.setOpen(false);
                    await props.loadItems();
                    setSnackbar({
                        visible: true,
                        variant: 'success',
                        message: 'Dados Salvos!'
                    })
                }
                return
            }

            const responseEditar = await api.put(`/${props.route}/${props.item[props.iditem]}`, dto, {
                headers: {
                    Authorization: 'Bearer ' + cookies.get('token')
                }
            });

            if (responseEditar.status === 204) {
                props.setOpen(false);
                await props.loadItems();
                setSnackbar({
                    visible: true,
                    variant: 'success',
                    message: 'Dados Salvos!'
                })
            }
        } catch (e) {
            setSnackbar({
                visible: true,
                variant: 'error',
                message: 'Erro ao salvar!'
            })
        }
    }

    return (
        <React.Fragment>
            <KSnackbar
                snackbar={snackbar}
                setSnackbar={setSnackbar}
            />
            <Dialog
                open={props.open}
                fullWidth={true}
                maxWidth={props.maxWidth}
                onClose={() => props.setOpen(false) || null}
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes.icon}>{props.title}</Typography>
                        <IconButton onClick={() => props.setOpen(false) || null}>
                            <Close className={classes.icon} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ padding: props.noPadding ? 0 : 'auto' }}>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickSalvar} className={classes.button} startIcon={<Save style={{ color: 'white' }} />}>
                        Salvar
                </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default KDialogCadastro;