// import React from 'react';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
// import InfoIcon from '@material-ui/icons/Info';
// import CloseIcon from '@material-ui/icons/Close';
// import { amber, green } from '@material-ui/core/colors';
// import IconButton from '@material-ui/core/IconButton';
// import Snackbar from '@material-ui/core/Snackbar';
// import SnackbarContent from '@material-ui/core/SnackbarContent';
// import WarningIcon from '@material-ui/icons/Warning';
// import { makeStyles, Theme } from '@material-ui/core/styles';

// const variantIcon: any = {
//   success: CheckCircleIcon,
//   warning: WarningIcon,
//   error: ErrorIcon,
//   info: InfoIcon,
// };

// const useStyles1 = makeStyles((theme: Theme) => ({
//   success: {
//     backgroundColor: green[600],
//   },
//   error: {
//     backgroundColor: theme.palette.error.dark,
//   },
//   info: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   warning: {
//     backgroundColor: amber[700],
//   },
//   icon: {
//     fontSize: 20,
//   },
//   iconVariant: {
//     opacity: 0.9,
//     marginRight: theme.spacing(1),
//   },
//   message: {
//     display: 'flex',
//     alignItems: 'center',
//   },
// }));

// const MySnackbarContentWrapper = (props: any) => {
//   const classes = useStyles1();
//   const { className, message, onClose, variant, ...other } = props;
//   const Icon = variantIcon[variant];

//   return (
//     <SnackbarContent
//       className={clsx(classes[variant], className)}
//       aria-describedby="client-snackbar"
//       message={
//         <span id="client-snackbar" className={classes.message}>
//           <Icon className={clsx(classes.icon, classes.iconVariant)} />
//           {message}
//         </span>
//       }
//       action={[
//         <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
//           <CloseIcon className={classes.icon} />
//         </IconButton>,
//       ]}
//       {...other}
//     />
//   );
// }

// MySnackbarContentWrapper.propTypes = {
//   className: PropTypes.string,
//   message: PropTypes.string,
//   onClose: PropTypes.func,
//   variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
// };

// const useStyles2 = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));


// const KSnackbar = (props: IKSnackbar) => {
//   const handleClose = (event: any, reason: any) => {
//     if (reason === 'clickaway') {
//       return;
//     }

//     props.setSnackbar({
//       ...props.snackbar,
//       visible: false
//     });
//   };

//   return (
//     <div>
//       <Snackbar
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'center',
//         }}
//         open={props.snackbar.visible}
//         autoHideDuration={props.autoHideDuration || 4000}
//         onClose={handleClose}
//       >
//         <MySnackbarContentWrapper
//           onClose={handleClose}
//           variant={props.snackbar.variant}
//           message={props.snackbar.message}
//         />
//       </Snackbar>
//     </div>
//   );
// }

// export default KSnackbar;

import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
interface IKSnackbar {
  snackbar: ISnack
  setSnackbar: Function
  autoHideDuration?: number
}

interface ISnack {
  visible: boolean
  variant: "success" | "info" | "warning" | "error" | undefined
  message: string
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function KSnackbar(props: IKSnackbar) {
  const classes = useStyles();

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setSnackbar({
      ...props.snackbar,
      visible: false
    });

  };

  return (
    <div className={classes.root}>
      <Snackbar open={props.snackbar.visible} autoHideDuration={props.autoHideDuration || 4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.snackbar.variant}>
          {props.snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}