import React, { useState, useEffect } from 'react';
import { Table, TableContainer, TableBody, TableHead, TableRow, List, ListItem, Typography, ListItemText, ListItemIcon, Divider, InputAdornment, useTheme, useMediaQuery, TableCell, Toolbar, Paper, Theme, TextField, Grid, IconButton, Button } from '@material-ui/core';
import { EditOutlined, SearchOutlined, AddCircleOutline, PrintOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import KDialogCadastro from '../KDialogCadastro';
import KFloatButton from '../kfloatbutton';
import MyDocument from '../../pages/Cliente/Report';

const useStyles = makeStyles((theme: Theme) => ({
    containerToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tableRow: {
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04);'
        }
    },
    button: {
        background: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.primary.dark
        }
    },
}))

interface IProps {
    itemsHeader: Array<string>
    itemsName: Array<string>
    items: Array<any>
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined
    setItems: Function
    editable: boolean
    searchBar: boolean
    searchBarItems: Array<string>
    cadastro: boolean
    children: React.ReactElement
    title: string
    item: any
    setItem: Function
    route: string
    iditem: string
    loadItems: Function
    validator: any
    noPadding?: boolean
    report?: boolean
    reportName?: string
    initialState?: any
}

const KTable = (props: IProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const initialItems = props.items;
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [items, setItems] = useState<Array<any>>(initialItems);
    const [modalCadastro, setModalCadastro] = useState<boolean>(false);
    const [openRelatorio, setOpenRelatorio] = useState<boolean>(false);
    const [openModalRelatorio, setOpenModalRelatorio] = useState<boolean>(false);

    const handlePesquisaChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let lista = props.items.filter(item => {
            let achou = props.searchBarItems.some(params => item[params].toString().toLowerCase().includes(event.target.value.toLowerCase()));
            if (props.reportName === 'clientes' && props.reportName) {
                if (item['nome'].toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item['cidadePrincipal']['nome'].toString().toLowerCase().includes(event.target.value.toLowerCase())) {
                    return true;
                }
            }

            if (achou) {
                return true;
            }
            return false;
        });
        setItems(lista);
    }

    const handleModalOpen = (item: any) => {
        props.setItem(item);
        setModalCadastro(true);
    }

    useEffect(() => {
        setItems(props.items)
    }, [props.items])

    useEffect(() => {
        setTimeout(() => {
            setOpenRelatorio(true);
        }, 1);
    }, [])

    return (
        <React.Fragment>
            {props.report && openRelatorio && props.reportName === 'clientes' ? (
                <MyDocument open={openModalRelatorio} setOpen={setOpenModalRelatorio} clientes={items} />
            ) : null}
            {props.cadastro ? (
                <KDialogCadastro
                    title={props.title}
                    maxWidth={props.maxWidth}
                    open={modalCadastro}
                    setOpen={setModalCadastro}
                    item={props.item}
                    route={props.route}
                    iditem={props.iditem}
                    loadItems={props.loadItems}
                    validator={props.validator || null}
                    noPadding={props.noPadding}
                >
                    {props.children}
                </KDialogCadastro>
            ) : null}
            <Paper>
                <Toolbar>
                    <Grid container spacing={2} item xs={12} className={classes.containerToolbar}>
                        <Grid item xs={!responsive ? 4 : 12} className={classes.gridItem}>
                            {props.searchBar ? (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handlePesquisaChanged}
                                    placeholder="pesquisar..."
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchOutlined />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                                {props.report && !responsive ? (
                                                    <InputAdornment position="end">
                                                        <IconButton size="small" onClick={() => setOpenModalRelatorio(true)}>
                                                            <PrintOutlined />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ) : null
                                                }
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            ) : null}
                        </Grid>
                        {!responsive ? (
                            <Grid item xs={2} className={classes.gridItem}>
                                {props.cadastro ? (
                                    <Button onClick={() => handleModalOpen({ ativo: true, ...props.initialState })} className={classes.button} startIcon={<AddCircleOutline style={{ color: theme.palette.secondary.main }} />} fullWidth>
                                        Novo
                                    </Button>
                                ) : null}
                            </Grid>
                        ) : (
                                <Grid item xs={2} className={classes.gridItem}>
                                    {props.cadastro ? (
                                        <KFloatButton actions={[]} handleClick={() => handleModalOpen({ ativo: true })} />
                                    ) : null}
                                </Grid>
                            )}
                    </Grid>
                </Toolbar>
                {!responsive ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {props.editable ? (
                                        <TableCell key="edit"></TableCell>
                                    ) : null}
                                    {props.itemsHeader.map(item => (
                                        <TableCell key={item}>{item}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.map(item => (
                                    <TableRow key={item[0]} className={classes.tableRow}>
                                        {props.editable ? (
                                            <TableCell key={item[0]}>
                                                <IconButton onClick={() => handleModalOpen(item)} key={item[0]} >
                                                    <EditOutlined key={item[0]} style={{ color: theme.palette.primary.main }} />
                                                </IconButton>
                                            </TableCell>
                                        ) : null}
                                        {props.itemsName.map(name => (
                                            <TableCell key={item[name]}>{item[name]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                        <List>
                            {items.map(item => (
                                <div>
                                    <ListItem alignItems="flex-start">
                                        <ListItemIcon>
                                            <IconButton onClick={() => handleModalOpen(item)}>
                                                <EditOutlined />
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item[props.itemsName[1]]}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        style={{ display: 'inline' }}
                                                        color="textPrimary"
                                                    >
                                                        {props.itemsName.map(name => (
                                                            <React.Fragment key={item[name]}>
                                                                { name === props.itemsName[1] ? null : name === props.iditem ? null : (
                                                                    <React.Fragment>
                                                                        {item[name]}
                                                                        <br />
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            ))}
                        </List>
                    )}
            </Paper>
        </React.Fragment>
    )

}

export default KTable;