import api from '../../Api';
import Cookies from 'universal-cookie';

const cookies = new Cookies;

interface IEstadoRepositorio {
    getSearchField: Function
}


const estadoRepositorio: IEstadoRepositorio = {
    getSearchField: async () => {
        const response = await api.get('/estado', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        const listaCidades = response.data.map((item: any) => {
            return {
                label: item.nome,
                value: item.idestado,
                sigla: item.sigla
            }
        })
        return listaCidades;
    }
}

export default estadoRepositorio;