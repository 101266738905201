import React from 'react';
import Routes from './routes';
import { ThemeProvider } from '@material-ui/styles';
import ThemeApp from './theme';

function App() {
  return (
    <ThemeProvider theme={ThemeApp}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
