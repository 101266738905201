import React, { useState } from 'react';
import { Card, Box, Button, CardContent, Grid, TextField, Container, CardMedia, Typography, Grow, CircularProgress, InputAdornment, useTheme, useMediaQuery } from '@material-ui/core';
import { AccountCircle, LockRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import Cookie from 'universal-cookie';

import LoginLogo from '../../images/Man_Using Laptop.svg';
import usuarioRepositorio from '../../repositorios/usuario/usuarioRepositorio';
import Logo from '../../images/Logo.svg';
import KSnackbar from '../../components/KSnackbar';
import { ISnack } from '../../Types';

interface IUsuario {
    usuario: string
    senha: string
}

const Login: React.FC = (props) => {
    const classes = useStyles();
    const cookies = new Cookie;
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: 'warning',
        message: 'Usuário e/ou senha incorreto(s)!'
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [usuario, setUsuario] = useState<IUsuario>({
        usuario: '',
        senha: ''
    })

    const handleChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsuario({
            ...usuario,
            [event.target.name]: event.target.value
        })
    }

    const logar = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setTimeout(async () => {
            try {
                const response = await usuarioRepositorio.auth(usuario);
                setIsLoading(false);
                if (response.status !== 200) {
                    setSnackbar({
                        ...snackbar,
                        visible: true
                    });
                    return;
                }
                cookies.set('token', response.data.token, { path: '/', expires: new Date(Date.now() + 36000000) });
                cookies.set('sessionId', response.data.sessionId, { path: '/', expires: new Date(Date.now() + 36000000) });
                history.push('/dashboard');
            } catch (e) {
                setIsLoading(false);
                setSnackbar({
                    ...snackbar,
                    visible: true
                });
            }
        }, 1000);
    }

    return (
        <Box>
            <KSnackbar
                snackbar={snackbar}
                setSnackbar={setSnackbar}
            />
            <Container className={classes.root}>
                <Grow in={true} timeout={1000}>
                    <Card className={classes.card}>
                        {!responsive ? (
                            <CardMedia image={LoginLogo} component="img" style={{
                                width: '100%',
                                maxWidth: 400,
                                height: 'auto'
                            }} />
                        ) : null}
                        <CardContent className={classes.content}>
                            <form onSubmit={logar}>
                                <Grid container item xs={12} spacing={3} style={{
                                    margin: 0,
                                    display: 'flex',
                                    flexDirection: responsive ? 'column' : 'row',
                                    justifyContent: 'center',
                                    alignItems: responsive ? 'normal' : 'center'
                                }}>
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={Logo} alt="logo KG" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="usuario"
                                            onChange={handleChanged}
                                            variant="outlined"
                                            margin="dense"
                                            label="Usuário"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircle style={{ color: 'grey' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            type="password"
                                            fullWidth
                                            name="senha"
                                            onChange={handleChanged}
                                            variant="outlined"
                                            margin="dense"
                                            label="Senha"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockRounded style={{ color: 'grey' }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            disabled={isLoading}
                                            fullWidth
                                            type="submit"
                                            className={classes.button}

                                        >
                                            {isLoading ? (
                                                <CircularProgress color="secondary" size={24} />
                                            ) : (
                                                    <div>
                                                        Entrar
                                                    </div>
                                                )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card >
                </Grow>
            </Container>
        </Box >
    )
}

export default Login;