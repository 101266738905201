import pedidoVendaRepositorio from "../../repositorios/pedidoVenda";
import usuarioRepositorio from "../../repositorios/usuario/usuarioRepositorio";
import tipoPagamentoRepositorio from "../../repositorios/tipoPagamento";
import representadaRepositorio from "../../repositorios/representada";
import KSearchField from "../../components/KSearchfield/index";
import produtoRepositorio from "../../repositorios/produto";
import clienteRepositorio from "../../repositorios/cliente";
import unidadeRepositorio from "../../repositorios/unidade";
import KFloatButton from "../../components/kfloatbutton";
import KDatePicker from "../../components/KDatePicker";
import OrdemCarregamento from "./OrdemCarregamento";
import KSnackbar from "../../components/KSnackbar";
import PedidoVendaReport from "./PedidoVendaReport";
import currencyFormatter from "currency-formatter";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import KSelect from "../../components/KSelect";
import KAppBar from "../../components/KAppBar";
import KLoader from "../../components/KLoader";
import ComissaoReport from "./ComissaoReport";
import { useHistory } from "react-router-dom";
import { ISnack } from "../../Types";
import {
    EditOutlined,
    SearchOutlined,
    AddCircleOutline,
    Close,
    LocalShippingOutlined,
    PrintOutlined,
    MonetizationOnOutlined,
} from "@material-ui/icons";
import moment from "moment";
import {
    Breadcrumbs,
    Link,
    Typography,
    Table,
    Toolbar,
    Tooltip,
    AppBar,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    InputAdornment,
    useMediaQuery,
    useTheme,
    TableHead,
    Grid,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    TableContainer,
    Theme,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    containerToolbar: {
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
    },
    gridItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    gridItemEnd: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    tableRow: {
        "&:hover": {
            background: "rgba(0, 0, 0, 0.04);",
        },
    },
    button: {
        background: theme.palette.primary.main,
        width: 150,
        color: "white",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    },
}));

const ListaPedidoVenda = () => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useStyles();
    const [ordemCarregamento, setOrdemCarregamento] = useState<any | null>(1);
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [situacao, setSituacao] = useState<any | null>(1);
    const [frete, setFrete] = useState<any | null>(1);
    const [emissao, setEmissao] = useState<Date | null>(new Date());
    const [modalOrdemCarregamento, setModalOrdemCarregamento] =
        useState<boolean>(false);
    const [snackbar, setSnackbar] = useState<ISnack>({
        visible: false,
        variant: "success",
        message: "Dados Salvos",
    });
    const situacoes = [
        {
            label: "Pendente",
            value: 1,
            color: "#ef6c00",
        },
        {
            label: "Carregado",
            value: 2,
            color: "#2e7d32",
        },
        {
            label: "Cancelado",
            value: 3,
            color: "#c62828",
        },
        {
            label: "Todos",
            value: 4,
            color: "#c62828",
        },
    ];
    const ordensCarregamento = [
        {
            label: "Não",
            value: 1,
        },
        {
            label: "Sim",
            value: 2,
        },
    ];
    const fretes = [
        {
            label: "Emitente",
            value: 1,
        },
        {
            label: "Destinatário",
            value: 2,
        },
    ];
    const [modalCadastro, setModalCadastro] = useState<boolean>(false);
    const [modalPedidoReport, setModalPedidoReport] = useState<boolean>(false);
    const [modalComissaoReport, setModalComissaoReport] =
        useState<boolean>(false);
    const [pesquisa, setPesquisa] = useState<string>("");
    const [pesquisaSituacao, setPesquisaSituacao] = useState<any | null>(4);
    const [dataInicio, setDataInicio] = useState<Date>(new Date());
    const [dataFim, setDataFim] = useState<Date>(new Date());
    const [pedidosVenda, setPedidosVenda] = useState<Array<any>>([]);
    const [pedidosVendaInicial, setPedidosVendaInicial] = useState<Array<any>>(
        []
    );
    const [pedidoVenda, setPedidoVenda] = useState<any | null>({
        titulos: [],
        ordensCarregamento: [],
        comissao: 0,
        dataemissao: new Date(),
    });
    const [produtos, setProdutos] = useState<Array<any>>([]);
    const [produto, setProduto] = useState<any | null>(null);
    const [unidades, setUnidades] = useState<Array<any>>([]);
    const [unidade, setUnidade] = useState<any | null>(null);
    const [representadas, setRepresentadas] = useState<Array<any>>([]);
    const [representada, setRepresentada] = useState<any | null>(null);
    const [clientes, setClientes] = useState<Array<any>>([]);
    const [cliente, setCliente] = useState<any | null>(null);
    const [usuarios, setUsuarios] = useState<Array<any>>([]);
    const [usuario, setUsuario] = useState<any | null>(null);
    const [tiposPagamento, setTiposPagamento] = useState<Array<any>>([]);
    const [tipoPagamento, setTipoPagamento] = useState<any | null>(null);

    const handleLinkClick = (path: string) => {
        history.push(path);
    };

    const getFormatDefault = (value: number) => {
        return currencyFormatter.format(value, {
            symbol: "",
            decimal: ",",
            thousand: ".",
            precision: 3,
            format: "%v", // %s is the symbol and %v is the value
        });
    };

    const handlePesquisaChanged = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPesquisa(event.target.value);
    };

    const handlechanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPedidoVenda({
            ...pedidoVenda,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeAuto = (campo: any, valor: any) => {
        setPedidoVenda({
            ...pedidoVenda,
            [campo]: valor,
        });
    };

    const handleModalClose = () => {
        setModalCadastro(false);
        setPedidoVenda({
            titulos: [],
            ordensCarregamento: [],
            comissao: 0,
            dataemissao: new Date(),
        });
        setProduto(null);
        setUnidade(null);
        setRepresentada(null);
        setCliente(null);
        setTipoPagamento(null);
    };

    const onClickSalvar = async () => {
        try {
            let dto = {
                ...pedidoVenda,
                idemnomede: pedidoVenda.idcliente,
                valorcompra: pedidoVenda.valorvenda,
                idtipopagamento: tipoPagamento.value,
                totalcompra: pedidoVenda.totalvenda,
                dataemissao: emissao,
                ordemcarregamento: ordemCarregamento === 1 ? true : false,
                situacao: Number(situacao) || 0,
                frete: Number(frete) || 0,
            };
            if (!dto.idproduto) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Produto Não pode ser vazio!",
                });
                return;
            }
            if (!dto.quantidade) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Quantidade Não pode ser vazio!",
                });
                return;
            }
            if (!dto.peso) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Peso Não pode ser vazio!",
                });
                return;
            }
            if (!dto.valorvenda) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Valor de venda Não pode ser vazio!",
                });
                return;
            }
            if (!situacao) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Situação Não pode ser vazio!",
                });
                return;
            }
            if (!dto.idrepresentada) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Representada Não pode ser vazio!",
                });
                return;
            }
            if (!dto.idcliente) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Cliente Não pode ser vazio!",
                });
                return;
            }
            if (!dto.idtipopagamento) {
                setSnackbar({
                    visible: true,
                    variant: "error",
                    message: "Condição de pagamento Não pode ser vazio!",
                });
                return;
            }

            let titulos = pedidoVenda.titulos.map(
                (item: any, index: number) => {
                    return {
                        ...item,
                        valor: (
                            Number(pedidoVenda.totalvenda) /
                            tipoPagamento.parcelas.length
                        ).toFixed(2),
                    };
                }
            );

            dto.titulos = titulos;

            setIsLoading(true);

            if (!pedidoVenda.idpedidovenda) {
                const responseSalvar = await pedidoVendaRepositorio.add(dto);

                if (responseSalvar.status === 201) {
                    setModalCadastro(false);
                    setPedidoVenda({
                        titulos: [],
                        ordensCarregamento: [],
                        comissao: 0,
                        dataemissao: new Date(),
                    });
                    await loadPedidosVenda();
                    setSnackbar({
                        visible: true,
                        variant: "success",
                        message: "Dados Salvos!",
                    });
                }
                setIsLoading(false);
                return;
            }

            const responseEditar = await pedidoVendaRepositorio.update(dto);

            if (responseEditar.status === 204) {
                setModalCadastro(false);
                setPedidoVenda({
                    titulos: [],
                    ordensCarregamento: [],
                    comissao: 0,
                    dataemissao: new Date(),
                });
                await loadPedidosVenda();
                setSnackbar({
                    visible: true,
                    variant: "success",
                    message: "Dados Salvos!",
                });
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setSnackbar({
                visible: true,
                variant: "error",
                message: "Erro ao salvar!",
            });
        }
    };

    const handleChangedNumber = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        try {
            if (event.target.value) {
                if (Number(event.target.value.replace(",", ".")) < 0) {
                    return;
                }
            }
            setPedidoVenda({
                ...pedidoVenda,
                [event.target.name]:
                    event.target.value && Number(event.target.value) >= 0
                        ? Number(event.target.value.replace(",", "."))
                        : null,
            });
        } catch {
            return;
        }
    };

    const handleChangedComissao = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        try {
            setPedidoVenda({
                ...pedidoVenda,
                [event.target.name]:
                    event.target.value && Number(event.target.value) >= 0
                        ? Number(event.target.value.replace(",", "."))
                        : 0,
            });
        } catch {
            return;
        }
    };

    const onClickCancelar = async () => {
        try {
            setIsLoading(true);
            await pedidoVendaRepositorio.cancelarPedido(
                pedidoVenda.idpedidovenda
            );
            await loadPedidosVenda();
            setIsLoading(false);
            setSnackbar({
                visible: true,
                variant: "success",
                message: "Pedido cancelado com sucesso!",
            });
            setModalCadastro(false);
        } catch (e) {
            setIsLoading(false);
            setSnackbar({
                visible: true,
                variant: "error",
                message: "Não foi possível cancelar pedido!",
            });
        }
    };

    const onClickPesquisar = async () => {
        try {
            const dto = {
                pesquisa,
                dataInicio,
                dataFim,
                situacao: Number(pesquisaSituacao) || 1,
            };
            const response = await pedidoVendaRepositorio.getFiltrado(dto);
            setPedidosVenda(response.data);
        } catch (e) {
            setSnackbar({
                message: "Erro ao pesquisar",
                variant: "error",
                visible: true,
            });
        }
    };

    const getSituacao = (situacao: any) => {
        return (
            situacoes.find((item: any) => item.value == situacao) || {
                label: "",
                value: 0,
                color: "#c62828",
            }
        );
    };

    const handleModalOpen = (item: any) => {
        setPedidoVenda({});
        setPedidoVenda(item);
        setModalCadastro(true);
    };

    const handleModalOrdemOpen = (item: any) => {
        setPedidoVenda(item);
        setModalOrdemCarregamento(true);
    };

    const handlePedidoVendaReportClick = (item: any) => {
        setPedidoVenda(item);
        setModalPedidoReport(true);
    };

    const handleComissaoReportClick = () => {
        setModalComissaoReport(true);
    };

    const loadPedidosVenda = async () => {
        try {
            setIsLoading(true);
            const response = await pedidoVendaRepositorio.getAll();
            const produtosResponse = await produtoRepositorio.getSearchField();
            const representadaResponse =
                await representadaRepositorio.getSearchField();
            const clienteResponse = await clienteRepositorio.getSearchField();
            const tipoPagamentoResponse =
                await tipoPagamentoRepositorio.getSearchField();
            const usuarioResponse = await usuarioRepositorio.getSearchField();
            const unidadeResponse =
                await unidadeRepositorio.getSearchFieldEmbalagem();

            setProdutos(produtosResponse);
            setRepresentadas(representadaResponse);
            setClientes(clienteResponse);
            setTiposPagamento(tipoPagamentoResponse);
            setPedidosVenda(response.data);
            setPedidosVendaInicial(response.data);
            setUsuarios(usuarioResponse);
            setUnidades(unidadeResponse);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setSnackbar({
                visible: true,
                variant: "error",
                message: "Não foi possível carregar lista.",
            });
        }
    };

    const getClientePorId = (id: any) => {
        let resultado = clientes.find((item) => item.value === id);
        return resultado ? resultado.label : "";
    };

    const getRepresentadaPorId = (id: any) => {
        let resultado = representadas.find((item) => item.value === id);
        return resultado ? resultado.label : "";
    };

    const getUsuarioPorId = (id: any) => {
        let resultado = usuarios.find((item) => item.value === id);
        return resultado ? resultado.label : "";
    };

    useEffect(() => {
        (async () => {
            await loadPedidosVenda();
        })();
    }, []);

    useEffect(() => {
        if (produto) {
            let unidadeProposta = unidades.find(
                (item) => item.value === produto.idunidade
            );
            setUnidade(unidadeProposta);
        } else {
            setUnidade(null);
        }
    }, [produto, setUnidade, unidades]);

    useEffect(() => {
        let peso = pedidoVenda.peso || "";
        let totalVenda = pedidoVenda.totalvenda || "";
        let titulos = pedidoVenda.titulos;

        if (unidade && pedidoVenda.quantidade) {
            if (
                !isNaN(Number(pedidoVenda.quantidade)) &&
                Number(pedidoVenda.quantidade) >= 0
            ) {
                peso = Number(pedidoVenda.quantidade) * Number(unidade.fator);
            }
        }

        if (pedidoVenda.quantidade && pedidoVenda.valorvenda) {
            if (
                !isNaN(Number(pedidoVenda.valorvenda)) &&
                Number(pedidoVenda.valorvenda) >= 0
            ) {
                totalVenda =
                    Number(pedidoVenda.valorvenda) *
                    Number(pedidoVenda.quantidade);
            }
        }

        if (totalVenda && tipoPagamento) {
            let dias = 0;
            titulos = tipoPagamento.parcelas.map((item: any, index: number) => {
                dias = item.dia;
                return {
                    parcela: index + 1,
                    datavencimento: moment(
                        new Date(pedidoVenda.dataemissao)
                    ).add(dias, "days"),
                    valor: (totalVenda / tipoPagamento.parcelas.length).toFixed(
                        2
                    ),
                };
            });
        }

        setPedidoVenda({
            ...pedidoVenda,
            peso: Number(peso).toFixed(2),
            totalcompra: Number(totalVenda).toFixed(2),
            totalvenda: Number(totalVenda).toFixed(2),
            titulos: titulos,
        });
    }, [
        unidade,
        pedidoVenda.valorcompra,
        pedidoVenda.valorvenda,
        pedidoVenda.quantidade,
        tipoPagamento,
    ]);

    useEffect(() => {
        setProduto(
            pedidoVenda.idpedidovenda
                ? produtos.find(
                      (item: any) => item.value == pedidoVenda.idproduto
                  )
                : null
        );
        setUnidade(
            pedidoVenda.idpedidovenda
                ? unidades.find(
                      (item: any) => item.value == pedidoVenda.produto.idunidade
                  )
                : null
        );
        setRepresentada(
            pedidoVenda.idpedidovenda
                ? representadas.find(
                      (item: any) => item.value == pedidoVenda.idrepresentada
                  )
                : null
        );
        setCliente(
            pedidoVenda.idpedidovenda
                ? clientes.find(
                      (item: any) => item.value == pedidoVenda.idcliente
                  )
                : null
        );
        setTipoPagamento(
            pedidoVenda.idpedidovenda
                ? tiposPagamento.find(
                      (item: any) => item.value == pedidoVenda.idtipopagamento
                  )
                : null
        );
        setFrete(pedidoVenda.idpedidovenda ? pedidoVenda.frete : 2);
        setOrdemCarregamento(
            pedidoVenda.idpedidovenda
                ? pedidoVenda.ordemcarregamento
                    ? 2
                    : 1
                : 1
        );
        setSituacao(pedidoVenda.idpedidovenda ? pedidoVenda.situacao : 1);
        setUsuario(
            pedidoVenda.idpedidovenda
                ? usuarios.find(
                      (item: any) => item.value == pedidoVenda.idusuariogerador
                  )
                : null
        );
        setEmissao(
            pedidoVenda.idpedidovenda
                ? new Date(pedidoVenda.dataemissao)
                : new Date()
        );
    }, [pedidoVenda.idpedidovenda]);

    return (
        <KAppBar>
            <div>
                <KLoader isLoading={isLoading} />
                <KSnackbar snackbar={snackbar} setSnackbar={setSnackbar} />
                <OrdemCarregamento
                    open={modalOrdemCarregamento}
                    setOpen={setModalOrdemCarregamento}
                    pedidoVenda={pedidoVenda}
                    setPedidoVenda={setPedidoVenda}
                />
                {modalPedidoReport ? (
                    <PedidoVendaReport
                        open={modalPedidoReport}
                        setOpen={setModalPedidoReport}
                        pedidoVenda={pedidoVenda}
                        unidades={unidades}
                        tiposPagamento={tiposPagamento}
                    />
                ) : null}
                {modalComissaoReport ? (
                    <ComissaoReport
                        open={modalComissaoReport}
                        setOpen={setModalComissaoReport}
                        pedidosVenda={pedidosVenda}
                        dataInicio={dataInicio}
                        dataFim={dataFim}
                    />
                ) : null}
                <Dialog
                    open={modalCadastro}
                    onClose={handleModalClose}
                    maxWidth="md"
                    fullWidth
                    fullScreen={responsive}
                >
                    <AppBar position="relative">
                        <Toolbar
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                style={{ color: theme.palette.secondary.main }}
                            >
                                Pedido de Venda
                            </Typography>
                            <IconButton onClick={handleModalClose}>
                                <Close
                                    style={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid container item xs={12}>
                            <Grid item xs={!responsive ? 6 : 12}>
                                <Grid
                                    container
                                    style={{ margin: 0 }}
                                    spacing={1}
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Produto
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={!responsive ? 8 : 12}>
                                        <KSearchField
                                            itens={produtos}
                                            value={produto}
                                            setValue={setProduto}
                                            label="Descrição"
                                            name="idproduto"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 4 : 12}>
                                        <KSearchField
                                            disabled
                                            itens={unidades}
                                            value={unidade}
                                            setValue={setUnidade}
                                            label="Unidade"
                                            name="idunidade"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            disabled={!produto}
                                            value={pedidoVenda.quantidade || ""}
                                            name="quantidade"
                                            onChange={handleChangedNumber}
                                            fullWidth
                                            label="Quant."
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            disabled
                                            type="number"
                                            value={pedidoVenda.peso || ""}
                                            name="peso"
                                            onChange={handleChangedNumber}
                                            fullWidth
                                            label="Peso"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            value={pedidoVenda.valorvenda || ""}
                                            name="valorvenda"
                                            onChange={handleChangedNumber}
                                            fullWidth
                                            label="Valor Venda"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            disabled
                                            value={pedidoVenda.totalvenda || ""}
                                            name="totalvenda"
                                            onChange={handleChangedNumber}
                                            fullWidth
                                            label="Total Venda"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Dados
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <KSearchField
                                            itens={representadas}
                                            value={representada}
                                            setValue={setRepresentada}
                                            label="Representada"
                                            name="idrepresentada"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <KSearchField
                                            itens={clientes}
                                            value={cliente}
                                            setValue={setCliente}
                                            label="Cliente"
                                            name="idcliente"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            itens={tiposPagamento}
                                            value={tipoPagamento}
                                            setValue={setTipoPagamento}
                                            label="Cond. pagto."
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <TextField
                                            value={
                                                pedidoVenda.prazoretirada || ""
                                            }
                                            name="prazoretirada"
                                            onChange={handlechanged}
                                            fullWidth
                                            label="Prazo Retirada"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={
                                                pedidoVenda.localretirada || ""
                                            }
                                            name="localretirada"
                                            onChange={handlechanged}
                                            fullWidth
                                            label="Local Retirada"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            multiline
                                            rows={3}
                                            value={pedidoVenda.obs || ""}
                                            name="obs"
                                            onChange={handlechanged}
                                            fullWidth
                                            label="Observação"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={!responsive ? 6 : 12}
                                style={{
                                    padding: !responsive
                                        ? theme.spacing(0, 4)
                                        : 0,
                                }}
                            >
                                <Grid
                                    container
                                    style={{ margin: 0 }}
                                    spacing={2}
                                    item
                                    xs={12}
                                >
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Venda
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KDatePicker
                                            disabled
                                            date={emissao}
                                            setDate={setEmissao}
                                            label="Emissão"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            type="number"
                                            value={pedidoVenda.comissao || ""}
                                            name="comissao"
                                            onChange={handleChangedComissao}
                                            fullWidth
                                            label="Comissão %"
                                            variant="outlined"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KSelect
                                            disabled
                                            itens={ordensCarregamento}
                                            value={ordemCarregamento}
                                            setValue={setOrdemCarregamento}
                                            label="Ordem de carreg."
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSelect
                                            disabled
                                            itens={situacoes}
                                            value={situacao}
                                            setValue={setSituacao}
                                            onChange={handleChangeAuto}
                                            name="situacao"
                                            label="Situação"
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSelect
                                            itens={fretes}
                                            value={frete}
                                            setValue={setFrete}
                                            onChange={handleChangeAuto}
                                            name="frete"
                                            label="Frete"
                                        />
                                    </Grid>
                                    <Grid item xs={!responsive ? 6 : 12}>
                                        <KSearchField
                                            disabled
                                            itens={usuarios}
                                            value={usuario}
                                            setValue={setUsuario}
                                            label="Digitado por"
                                            name="idusuariogerador"
                                            onChange={handleChangeAuto}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Titulos
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <TableContainer
                                                style={{ maxHeight: 250 }}
                                            >
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center">
                                                                Parcela
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Vencimento
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Valor
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {pedidoVenda.titulos.map(
                                                            (item: any) => (
                                                                <TableRow
                                                                    key={
                                                                        item.idpedidovendatitulo
                                                                    }
                                                                >
                                                                    <TableCell align="center">
                                                                        {
                                                                            item.parcela
                                                                        }
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        {new Date(
                                                                            item.datavencimento
                                                                        ).toLocaleDateString()}
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        R$
                                                                        {getFormatDefault(
                                                                            item.valor
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onClickSalvar}
                            style={{
                                background: theme.palette.primary.main,
                                color: theme.palette.secondary.main,
                            }}
                        >
                            Confirmar Pedido
                        </Button>
                        {pedidoVenda.idpedidovenda &&
                        pedidoVenda.situacao !== 3 ? (
                            <Button
                                onClick={onClickCancelar}
                                style={{
                                    background: "#c62828",
                                    color: theme.palette.secondary.main,
                                }}
                            >
                                Cancelar
                            </Button>
                        ) : null}
                    </DialogActions>
                </Dialog>
                <Breadcrumbs separator=">">
                    <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLinkClick("/dashboard")}
                    >
                        Dashboard
                    </Link>
                    <Link
                        style={{ cursor: "pointer" }}
                        onClick={() => handleLinkClick("/pedidosvenda")}
                    >
                        Pedidos de Venda
                    </Link>
                </Breadcrumbs>
                <Typography variant="h5">Pedidos de Venda</Typography>
                <TableContainer>
                    <Paper>
                        <Toolbar>
                            <Grid
                                container
                                spacing={2}
                                item
                                xs={12}
                                className={classes.containerToolbar}
                            >
                                {!responsive ? (
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.gridItemEnd}
                                    >
                                        <Button
                                            onClick={() =>
                                                handleModalOpen({
                                                    ativo: true,
                                                    titulos: [],
                                                    ordensCarregamento: [],
                                                    comissao: 0,
                                                    dataemissao: new Date(),
                                                })
                                            }
                                            className={classes.button}
                                            startIcon={
                                                <AddCircleOutline
                                                    style={{
                                                        color: theme.palette
                                                            .secondary.main,
                                                    }}
                                                />
                                            }
                                        >
                                            Novo
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid
                                        item
                                        xs={2}
                                        className={classes.gridItem}
                                    >
                                        <KFloatButton
                                            actions={[]}
                                            handleClick={() =>
                                                handleModalOpen({
                                                    ativo: true,
                                                    titulos: [],
                                                    ordensCarregamento: [],
                                                    comissao: 0,
                                                    dataemissao: new Date(),
                                                })
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={!responsive ? 4 : 12}
                                    className={classes.gridItem}
                                >
                                    <TextField
                                        color="primary"
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        value={pesquisa}
                                        onChange={handlePesquisaChanged}
                                        placeholder="pesquisar..."
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={
                                                            onClickPesquisar
                                                        }
                                                        size="small"
                                                    >
                                                        <SearchOutlined />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={!responsive ? 2 : 12}
                                    className={classes.gridItem}
                                >
                                    <KDatePicker
                                        date={dataInicio}
                                        setDate={setDataInicio}
                                        label="Data Início"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={!responsive ? 2 : 12}
                                    className={classes.gridItem}
                                >
                                    <KDatePicker
                                        date={dataFim}
                                        setDate={setDataFim}
                                        label="Data Fim"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={!responsive ? 3 : 12}
                                    className={classes.gridItem}
                                >
                                    <KSelect
                                        itens={situacoes}
                                        value={pesquisaSituacao}
                                        setValue={setPesquisaSituacao}
                                        label="Situação"
                                    />
                                </Grid>
                            </Grid>
                        </Toolbar>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        {!responsive ? (
                                            <TableCell align="center"></TableCell>
                                        ) : null}
                                        <TableCell align="center">
                                            Pedido
                                        </TableCell>
                                        <TableCell align="center">
                                            Emissão
                                        </TableCell>
                                        <TableCell align="center">
                                            Situação
                                        </TableCell>
                                        <TableCell align="center">
                                            Cliente
                                        </TableCell>
                                        <TableCell align="center">
                                            Digitado por
                                        </TableCell>
                                        <TableCell align="center">
                                            Valor Total
                                        </TableCell>
                                        <TableCell align="center">
                                            Representada
                                        </TableCell>
                                        <TableCell align="center">
                                            {!responsive &&
                                            pedidosVenda.length > 0 ? (
                                                <Tooltip title="Gerar Relatório de Comissões">
                                                    <IconButton
                                                        onClick={
                                                            handleComissaoReportClick
                                                        }
                                                    >
                                                        <MonetizationOnOutlined
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .main,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pedidosVenda.map((item: any) => (
                                        <TableRow>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={() =>
                                                        handleModalOpen(item)
                                                    }
                                                >
                                                    <EditOutlined
                                                        style={{
                                                            color: theme.palette
                                                                .primary.main,
                                                        }}
                                                    />
                                                </IconButton>
                                            </TableCell>
                                            {!responsive ? (
                                                <TableCell align="center">
                                                    <Tooltip title="Imprimir pedido de venda">
                                                        <IconButton
                                                            onClick={() =>
                                                                handlePedidoVendaReportClick(
                                                                    item
                                                                )
                                                            }
                                                        >
                                                            <PrintOutlined
                                                                style={{
                                                                    color: theme
                                                                        .palette
                                                                        .primary
                                                                        .main,
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            ) : null}
                                            <TableCell align="center">
                                                {item.idpedidovendarepresentada}
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(
                                                    item.dataemissao
                                                ).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    style={{
                                                        color: getSituacao(
                                                            item.situacao
                                                        ).color,
                                                        border: `solid 1px ${
                                                            getSituacao(
                                                                item.situacao
                                                            ).color
                                                        }`,
                                                        borderRadius: 25,
                                                        padding: "0px 5px",
                                                    }}
                                                >
                                                    {
                                                        getSituacao(
                                                            item.situacao
                                                        ).label
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                {getClientePorId(
                                                    item.idcliente
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {getUsuarioPorId(
                                                    item.idusuariogerador
                                                )}
                                            </TableCell>
                                            <TableCell align="center">{`R$ ${getFormatDefault(
                                                item.totalvenda
                                            )}`}</TableCell>
                                            <TableCell align="center">
                                                {getRepresentadaPorId(
                                                    item.idrepresentada
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Ordem de carregamento">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleModalOrdemOpen(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <LocalShippingOutlined
                                                            style={{
                                                                color: theme
                                                                    .palette
                                                                    .primary
                                                                    .main,
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </TableContainer>
            </div>
        </KAppBar>
    );
};

export default ListaPedidoVenda;
