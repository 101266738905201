import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { AppBar, Dialog, DialogActions, DialogContent, Grid, IconButton, Table, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import currencyFormatter from 'currency-formatter';

import Logo from '../../../images/Logo.png';
import { Close } from '@material-ui/icons';

// Create styles
const styles = StyleSheet.create<any>({
    page: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    divider: {
        marginTop: 10
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    container: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    header: {
        fontSize: 12,
        color: 'grey',
    },
    header2: {
        fontSize: 8,
        color: 'grey'
    },
    total: {
        fontSize: 10,
        margin: '0px 5px'
    },
    image: {
        width: 111,
        height: 56
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableWithoutBorder: {
        display: "table",
        width: "auto",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableRowBorder: {
        margin: "auto",
        flexDirection: "row",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0
    },
    tableCol32: {
        width: "32%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol: {
        width: "14%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCol6: {
        width: "6%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColFooter: {
        width: "100%",
        borderStyle: "solid",
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableColWithoutBorder6: {
        marginBottom: 5,
        width: "6%"
    },
    tableColWithoutBorder: {
        marginBottom: 5,
        width: "14%"
    },
    tableColWithoutBorder32: {
        marginBottom: 5,
        width: "32%"
    },
    tableCell: {
        margin: "auto",
        marginTop: 5,
        fontSize: 8
    },
    tableCellFooter: {
        marginTop: 5,
        fontSize: 8
    },

});

interface IComissaoReport {
    open: boolean
    setOpen: Function
    pedidosVenda: Array<any>
    dataInicio: Date
    dataFim: Date
}

// Create Document Component
const ComissaoReport = (props: IComissaoReport) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [relatorio, setRelatorio] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setRelatorio(true);
        }, 10);

    }, [])

    const getFormatDefault = (value: number) => {
        return currencyFormatter.format(value, {
            symbol: '',
            decimal: ',',
            thousand: '.',
            precision: 2,
            format: '%v' // %s is the symbol and %v is the value
        });
    }

    const calcularComissao = (comissao: number, valor: number) => valor * (comissao / 100);
    const calcularComissaoTotal = (lista: Array<any>) => lista.reduce((acum, atual) => {
        acum = acum + (atual.totalvenda * (atual.comissao / 100))
        return acum;
    }, 0);
    const calcularPesoTotal = (lista: Array<any>) => lista.reduce((acum, atual) => {
        acum = acum + atual.peso
        return acum;
    }, 0);
    const calcularTotalVenda = (lista: Array<any>) => lista.reduce((acum, atual) => {
        acum = acum + atual.totalvenda
        return acum;
    }, 0);

    const groupBy = (items: Array<any>, key: any) => {
        let grouped = items.reduce(
            (result: any, item: any) => ({
                ...result,
                [item[key]]: [
                    ...(result[item[key]] || []),
                    item,
                ],
            }),
            [],
        );
        return Object.values(grouped);
    };

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                fullWidth
                maxWidth="md"
                fullScreen={responsive}
            >
                <AppBar position="relative">
                    <Toolbar style={{
                        background: '#646464',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{ color: '#fff' }}>Relatório de Comissões</Typography>
                        <IconButton onClick={() => props.setOpen(false)}>
                            <Close style={{ color: '#fff' }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent
                    style={{
                        minHeight: 600,
                        maxHeight: 600
                    }}
                >
                    <Grid container item xs={12} style={{ height: '580px' }}>
                        <Grid item xs={12} style={{ height: '100%' }}>
                            {relatorio ? (
                                <PDFViewer >
                                    <Document title="Relatório_Comissoes_KG">
                                        <Page size="A4" style={styles.page}>
                                            <View style={styles.container} fixed>
                                                <View style={styles.section}>
                                                    <Image
                                                        style={styles.image}
                                                        src={Logo}
                                                    />
                                                </View>
                                                <View style={styles.section}>
                                                    <Text style={styles.header}>Relatório de Comissões</Text>
                                                    <Text style={styles.header2}>Período {props.dataInicio.toLocaleDateString()} até {props.dataFim.toLocaleDateString()}</Text>
                                                </View>
                                                <View style={styles.section}>
                                                    <Text style={styles.header}>{`Data: ${new Date().toLocaleString().substr(0, 16)}`}</Text>
                                                </View>
                                            </View>
                                            <View fixed style={styles.tableColFooter}>
                                            </View>
                                            <View style={styles.container} fixed>
                                                <View style={styles.section} >
                                                    <Text style={styles.header}>KG REPRESENTAÇÃO DE CEREAIS LTDA.</Text>
                                                </View>
                                                <View style={styles.section} >
                                                    <Text style={styles.header} render={({ pageNumber, totalPages }) => (
                                                        `Página ${pageNumber} de ${totalPages}`
                                                    )} fixed />
                                                </View>
                                            </View>

                                            <View style={styles.tableWithoutBorder}>
                                                {groupBy(props.pedidosVenda, 'idrepresentada').map((item: any) => (
                                                    <View>
                                                        <View style={styles.section} >
                                                            <Text style={styles.header}>Representada:   {item[0].idrepresentada} - {item[0].representada.nome}</Text>
                                                        </View>
                                                        <View style={styles.table}>
                                                            {/* TableHeader */}
                                                            <View style={styles.tableRow}>
                                                                <View style={styles.tableCol6}>
                                                                    <Text style={styles.tableCell}>Pedido</Text>
                                                                </View>
                                                                <View style={styles.tableCol}>
                                                                    <Text style={styles.tableCell}>Emissão</Text>
                                                                </View>
                                                                <View style={styles.tableCol32}>
                                                                    <Text style={styles.tableCell}>Cliente</Text>
                                                                </View>
                                                                <View style={styles.tableCol}>
                                                                    <Text style={styles.tableCell}>Peso Pedido</Text>
                                                                </View>
                                                                <View style={styles.tableCol}>
                                                                    <Text style={styles.tableCell}>Valor Pedido (R$)</Text>
                                                                </View>
                                                                <View style={styles.tableCol6}>
                                                                    <Text style={styles.tableCell}>% Cms.</Text>
                                                                </View>
                                                                <View style={styles.tableCol}>
                                                                    <Text style={styles.tableCell}>Vl.Comissão</Text>
                                                                </View>
                                                            </View>
                                                            {/* TableContent */}
                                                        </View>
                                                        {item.map((subItem: any) => (
                                                            <View style={styles.tableRow} key={String(subItem.idpedidovenda)}>
                                                                <View style={styles.tableColWithoutBorder6}>
                                                                    <Text style={styles.tableCell}>{subItem.idpedidovendarepresentada}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder}>
                                                                    <Text style={styles.tableCell}>{new Date(subItem.dataemissao).toLocaleDateString()}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder32}>
                                                                    <Text style={styles.tableCell}>{subItem.cliente.nome}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder}>
                                                                    <Text style={styles.tableCell}>{getFormatDefault(subItem.peso)}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder}>
                                                                    <Text style={styles.tableCell}>{getFormatDefault(subItem.totalvenda)}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder6}>
                                                                    <Text style={styles.tableCell}>{getFormatDefault(subItem.comissao)}</Text>
                                                                </View>
                                                                <View style={styles.tableColWithoutBorder}>
                                                                    <Text style={styles.tableCell}>{getFormatDefault(calcularComissao(subItem.comissao, subItem.totalvenda))}</Text>
                                                                </View>
                                                            </View>
                                                        ))}
                                                        <View style={styles.tableRowBorder}>
                                                            <View style={styles.tableColWithoutBorder6}>
                                                                <Text style={styles.tableCell}></Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder}>
                                                                <Text style={styles.tableCell}></Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder32}>
                                                                <Text style={styles.tableCell}>{`Total da Representada --->`}</Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder}>
                                                                <Text style={styles.tableCell}>{getFormatDefault(calcularPesoTotal(item))}</Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder}>
                                                                <Text style={styles.tableCell}>{getFormatDefault(calcularTotalVenda(item))}</Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder6}>
                                                                <Text style={styles.tableCell}></Text>
                                                            </View>
                                                            <View style={styles.tableColWithoutBorder}>
                                                                <Text style={styles.tableCell}>{getFormatDefault(calcularComissaoTotal(item))}</Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                ))}
                                                <View style={styles.divider}></View>
                                                <View style={styles.tableRowBorder}>
                                                    <View style={styles.tableColWithoutBorder6}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder32}>
                                                        <Text style={styles.tableCell}>{`Total Geral --->`}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{getFormatDefault(calcularPesoTotal(props.pedidosVenda))}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{getFormatDefault(calcularTotalVenda(props.pedidosVenda))}</Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder6}>
                                                        <Text style={styles.tableCell}></Text>
                                                    </View>
                                                    <View style={styles.tableColWithoutBorder}>
                                                        <Text style={styles.tableCell}>{getFormatDefault(calcularComissaoTotal(props.pedidosVenda))}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View fixed style={styles.tableColFooter}>
                                                <Text style={styles.tableCellFooter}>KG Representação de Cereais LTDA.</Text>
                                            </View>
                                        </Page>
                                    </Document>
                                </PDFViewer>
                            ) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
};

export default ComissaoReport;