import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100vw',
        maxWidth: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    button: {
        background: theme.palette.primary.main,
        color: 'white',
        height: 36,
        '&.MuiButton-root:hover.Mui-disabled': {
            background: theme.palette.primary.dark
        },
        '&:hover': {
            background: theme.palette.primary.dark
        }
    },
    card: {
        width: '800px',
        padding: theme.spacing(3),
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(1)
        }
    }
}))

export default useStyles;